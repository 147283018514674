import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Row, Grid } from "antd";
import { Formik, Form } from "formik";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EditDocuments from "../EditDocuments/EditDocuments";
import BulkEdit from "../BulkEdit/BulkEdit";
import SingleEdit from "../SingleEdit/SingleEdit";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import { useSelector } from "react-redux";
import { getModuleAccess } from "store/slices/moduleAccessSlice";
import ActionModel from "../ActionModel/ActionModel";
import { fetchData } from "../commonFunction";
import { listItemsTypesSearch, listProjectsSearch } from "utils/RESTApi";
import DeleteDocument from "../DeleteDocument/DeleteDocument";
import { CommonError } from "utils/Helper/CommonError";
import {
  documentsByOrganizationIDCustom,
  searchDocumentsCustom,
} from "utils/Actions";

export default function SelectDocuments(props) {
  const {
    setSelectDoc,
    selectDoc,
    uploadedFiles,
    userOrganizationId,
    currentIdToken,
    treeData,
    folderArray,
    handleDocCustom = () => {},
    handleCustomForm = () => {},
  } = props;

  const { hasReadAccess } = useSelector((state) =>
    getModuleAccess(state, "Documents")
  );

  const [documentEditModal, setDocumentEditModal] = useState(false);
  const [singleEdit, setSingleEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [itemTypeList, setItemTypeList] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [documentId, setDocumentId] = useState("");
  const [document, setDocument] = useState([]);
  const [deleteDoc, setDeleteDoc] = useState(false);

  // [uploaded file in state] :: begin
  useEffect(() => {
    const uniqueArray = uploadedFiles.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
    setUploadedData([...uniqueArray]);
  }, [uploadedFiles]);
  // [uploaded file in state] :: end

  // [fetch project and itemType data] :: begin
  useEffect(() => {
    fetchData(
      userOrganizationId,
      listProjectsSearch,
      setLoading,
      setProjectList,
      setItemTypeList,
      setNextToken
    );
    fetchData(
      userOrganizationId,
      listItemsTypesSearch,
      setLoading,
      setProjectList,
      setItemTypeList,
      setNextToken
    );
  }, []);
  // [fetch project and itemType data] :: end

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
      render: (_, value) => {
        return (
          <>
            <Row gutter={[24, 0]}>
              <Button
                type="link"
                className="p-0 mr-20"
                onClick={() => {
                  setDocumentId(value?.id);
                  setDocument([
                    ...uploadedData.filter((item) => item?.id === value?.id),
                  ]);
                  setDocumentEditModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Button
                onClick={() => {
                  setDeleteDoc(true);
                  setDocumentId(value?.id);
                  setDocument([
                    ...uploadedData.filter((item) => item?.id === value?.id),
                  ]);
                  showRemainingData(value?.id);
                }}
                type="link"
                className="p-0 mr-20 redBtn"
              >
                <DeleteOutlined />
              </Button>
            </Row>
          </>
        );
      },
    },
  ];

  // remove selected data :: begins
  const showRemainingData = (id) => {
    const data = uploadedData.filter((item) => item.id !== id);

    setUploadedData(data);
  };
  // remove selected data :: end
  useEffect(() => {
    if (uploadedData?.length === 0) {
      setSelectDoc(false);
    }
  }, [uploadedData]);

  // selected rows data to attach item :: begin
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedDocument(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  // selected rows data to attach item :: end

  const customDeleteFunction = (val) => {
    const documentsAdded = uploadedData;

    try {
      documentsAdded.map(async (item, index) => {
        if (item?.id) {
          let filter = {
            id: { eq: item.id },
            organizationID: { eq: userOrganizationId },
            isDeleted: { ne: true },
            isFromMobile: { ne: true },
          };

          const sort = {};

          const response = await searchDocumentsCustom(
            filter,
            sort,
            9999,
            null,
            currentIdToken
          );

          if (index === 0) {
            setUploadedData([...response?.items]);
          } else {
            setUploadedData((prevData) => [...prevData, ...response?.items]);
          }

          if (item?.id === documentsAdded[documentsAdded.length - 1]?.id) {
            setDocumentId("");
            setNextToken(null);
            setDocumentEditModal(false);
          }
        }
      });
    } catch (error) {
      CommonError(error);
    } finally {
      setDeleteDoc(false);
    }
  };

  useEffect(() => {
    if (!selectDoc) {
      handleDocCustom("Close");
    } else {
      setSelectedDocument([]);
    }
  }, [selectDoc]);

  return (
    <>
      <Modal
        title="Select Document"
        width={497}
        centered
        className="modal"
        maskClosable={true}
        open={selectDoc}
        onCancel={() => {
          setSelectDoc(false);
        }}
        footer={[
          <Button
            onClick={() => {
              setSelectDoc(false);
              setOpen(true);
            }}
            disabled={selectedDocument?.length > 1 ? false : true}
          >
            Multiple Associate
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              setSelectDoc(false);
              setSingleEdit(true);
            }}
            disabled={selectedDocument?.length >= 1 ? false : true}
          >
            Single Associate
          </Button>,
        ]}
      >
        <Formik>
          <Form id="editDocument" className="upload-doc">
            <div className="mb-10">
              <SearchableTableWithSorting
                className={"overlay-table"}
                data={uploadedData}
                columns={columns}
                screens={screens}
                rowSelection={rowSelection}
                hideSelection={false}
                rowKey={"id"}
              />
            </div>
          </Form>
        </Formik>
      </Modal>

      <BulkEdit
        open={open}
        screens={screens}
        setOpen={() => setOpen()}
        selectedDocuments={selectedDocument}
        projectData={projectList}
        itemTypeData={itemTypeList}
        organizationID={userOrganizationId}
        currentIdToken={currentIdToken}
        handleCustomBulkEdit={(data) => handleCustomForm(data)}
      />

      <SingleEdit
        singleEdit={singleEdit}
        setSingleEdit={() => setSingleEdit()}
        projectData={projectList}
        itemTypeData={itemTypeList}
        documentData={selectedDocument}
        organizationID={userOrganizationId}
        currentIdToken={currentIdToken}
        activeForm={0}
        handleCustomForm={(data) => handleCustomForm(data)}
      />

      <EditDocuments
        documentEditModal={documentEditModal}
        setDocumentEditModal={setDocumentEditModal}
        currentIdToken={currentIdToken}
        handleCustomSubmit={(val) => customDeleteFunction(val)}
        treeData={treeData}
        folderArray={folderArray}
        documentToEdit={document}
      />

      <DeleteDocument
        deleteDoc={deleteDoc}
        setDeleteDoc={() => setDeleteDoc()}
        handleDelete={() => {
          customDeleteFunction();
        }}
        documentId={documentId}
        currentIdToken={currentIdToken}
        userOrganizationId={userOrganizationId}
        documentToDelete={document}
      />
    </>
  );
}
