import { Col, Row, Select, Space } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { Formik, Form } from "formik";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useInsertionEffect,
} from "react";
import { useSelector } from "react-redux";
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice";
import { itemsByItemTypeId, itemTypesByProjectId } from "utils/Actions";
import { debounceFn } from "utils/Helper/commonMethods";
import {
  listItemsSearch,
  listItemsTypesSearch,
  listProjectsSearch,
} from "utils/RESTApi";
import * as Yup from "yup";
import { fetchData } from "../commonFunction";

const AssociateForm = (props) => {
  const {
    actionType,
    projectData,
    itemTypeData,
    formName,
    handleSubmitForm = () => { },
    dataToEdit = [],
    bulkEdit,
    documentId,
    setFormRef,
  } = props;

  const userOrganizationId = useSelector(getOrganizationId);
  const formikRef = useRef();

  useInsertionEffect(() => {
    setFormRef(formikRef);
  }, [formikRef]);

  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [itemTypeValues, setItemTypeValues] = useState([]);
  const [itemsValue, setItemsValue] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);
  const [projectItemTypeMap, setProjectItemTypeMap] = useState({});
  const [itemTypeItemsMap, setItemTypeItemsMap] = useState({});
  const currentTokenId = useSelector(getCurrentToken);

  useEffect(() => {
    const projects = dataToEdit
      ?.map((item) => item?.projectId)
      .filter((item) => item !== null);

    if (dataToEdit.length > 0) {
      if (projects.length > 0) {
        setSelectedProjects(projects);
      } else {
        fetchItemTypes(false);
      }
    } else {
      setSelectedProjects([]);
      setItemTypeList([]);
    }
  }, [dataToEdit]);

  const filteredItemType = useMemo(() => {
    return formikRef?.current?.values?.itemType?.filter((item) =>
      itemTypeList.some((itemType) => itemType?.id === item)
    );
  }, [itemTypeList, formikRef?.current?.values?.itemType]);

  useEffect(() => {
    if (
      filteredItemType?.length !== formikRef?.current?.values?.itemType?.length
    ) {
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        itemType: filteredItemType,
      }));
    }
  }, [filteredItemType, formikRef]);

  useEffect(() => {
    const itemTypes = dataToEdit
      ?.map((item) => item?.itemTypeId)
      .filter((item) => item !== null);

    if (dataToEdit && itemTypes?.length > 0) {
      setItemsList([]);

      for (let index = 0; index < itemTypes.length; index++) {
        const element = itemTypes[index];
        fetchItems(element, itemTypes);
      }
      // itemTypes.map((item) => {
      //   if (item !== null) {
      //     fetchItems(item);
      //   }
      // });
    }
  }, [dataToEdit]);

  useEffect(() => {
    if (
      itemTypeData &&
      dataToEdit.length === 0 &&
      formikRef?.current?.values?.project?.length === 0
    ) {
      setItemTypeList(itemTypeData);
    }
  }, [itemTypeData, dataToEdit]);

  const filteredItems = useMemo(() => {
    return formikRef?.current?.values?.items?.filter((item) =>
      itemsList.some((itemType) => itemType?.id === item)
    );
  }, [itemsList, formikRef?.current?.values?.items]);

  useEffect(() => {
    if (filteredItems?.length !== formikRef?.current?.values?.items?.length) {
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        items: filteredItems,
      }));
    }
  }, [filteredItems, formikRef]);

  useEffect(() => {
    if (projectData) {
      setProjectList(projectData);
    }
  }, [projectData]);

  // "Projects list" :: begins
  let projectOptions = useMemo(() => {
    const options = projectList?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    return options;
  }, [projectList]);
  // "Projects list" :: end

  // "Items list" :: begins
  let itemsOptions = useMemo(() => {
    const options = itemsList?.map((item) => {
      return {
        label: item?.serialNumber,
        value: item?.id,
      };
    });

    return options;
  }, [itemsList]);
  // "Items list" :: end

  // "Item Types" :: begin
  let itemTypesOptions = useMemo(() => {
    const options = itemTypeList?.map((item) => {
      return {
        label: item?.number,
        value: item?.id,
      };
    });

    return options;
  }, [itemTypeList, projectList]);
  // "Item Types" :: begin

  // search function :: begin
  const handleCustomInputSearch = (searchText) => {
    debouncedCustomHandleInput(searchText);
  };

  const handleCustomSearch = (value, inputName) => {
    if (inputName === "project") {
      fetchData(
        userOrganizationId,
        listProjectsSearch,
        setLoadingData,
        setProjectList,
        setItemTypeList,
        setNextToken,
        value
      );
    } else if (inputName === "items") {
      fetchData(
        userOrganizationId,
        listItemsSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken,
        value
      );
    } else {
      fetchData(
        userOrganizationId,
        listItemsTypesSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken,
        value
      );
    }
  };

  const debouncedCustomHandleInput = debounceFn(handleCustomSearch, 200);
  // search function :: end

  // 'validation schema' :: begins
  const validationSchema = Yup.object().shape({
    itemType:
      actionType !== "Item"
        ? Yup.array()
          .min(1, "Item type is required")
          .required("Item type is required")
        : Yup.array().when(["project"], {
          is: (value) => value === null || value.length === 0,
          then: () =>
            Yup.array()
              .min(1, "Please select Project or Item Type")
              .required("Please select Project or Item Type"),
          otherwise: () => Yup.array().nullable(),
        }),
    items:
      actionType === "Item"
        ? Yup.array()
          .min(1, "Please select at least one item")
          .required("Please select items")
        : Yup.array().nullable(),
  });
  // 'validation schema' :: end

  // 'Initial values' :: begins
  const initialValues = useMemo(() => {
    const projects = dataToEdit?.map(item => item?.projectId).filter(Boolean);
    const itemTypes = dataToEdit?.map(item => item?.itemTypeId).filter(Boolean);
    const items = dataToEdit?.map(item => item?.itemsId).filter(Boolean);

    return {
      project: projects || [],
      itemType: itemTypes || [],
      items: items || [],
      documentId: bulkEdit && documentId,
    };
  }, [dataToEdit, bulkEdit, documentId]);

  // Load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!dataToEdit?.length) return;
      setLoadingData(true);

      try {
        // Load projects data
        const projects = dataToEdit.map(item => item?.projectId).filter(Boolean);

        if (projects.length > 0) {
          // Load item types for each project
          for (const projectId of projects) {
            const filter = { isDeleted: { ne: true } };
            const response = await itemTypesByProjectId(
              projectId,
              filter,
              null,
              500,
              currentTokenId
            );

            const newItemTypes = response?.data?.itemTypesByProjectId?.items || [];

            // Update project-itemType map
            setProjectItemTypeMap(prev => ({
              ...prev,
              [projectId]: newItemTypes.map(item => item.id)
            }));

            // Update itemType list
            setItemTypeList(prev => {
              const combined = [...prev, ...newItemTypes];
              return combined.filter((item, index, self) =>
                index === self.findIndex(t => t.id === item.id)
              );
            });
          }

          // Load items for each item type
          const itemTypes = dataToEdit.map(item => item?.itemTypeId).filter(Boolean);

          if (itemTypes.length > 0) {
            for (const itemTypeId of itemTypes) {
              const filter = { isDeleted: { ne: true } };
              const response = await itemsByItemTypeId(
                itemTypeId,
                filter,
                currentTokenId
              );

              const newItems = response?.items || [];

              // Update itemType-items map
              setItemTypeItemsMap(prev => ({
                ...prev,
                [itemTypeId]: newItems.map(item => item.id)
              }));

              // Update items list
              setItemsList(prev => {
                const combined = [...prev, ...newItems];
                return combined.filter((item, index, self) =>
                  index === self.findIndex(t => t.id === item.id)
                );
              });
            }
          }
        }
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        setLoadingData(false);
      }
    };

    loadInitialData();
  }, [dataToEdit, currentTokenId]);
  const fetchItems = async (itemTypeId, itemTypeArray) => {
    if (!itemTypeId) return;

    setLoadingData(true);
    try {
      const filter = { isDeleted: { ne: true } };
      const response = await itemsByItemTypeId(
        itemTypeId,
        filter,
        currentTokenId
      );
      const newItems = response?.items || [];

      // Update itemType-items relationship map
      setItemTypeItemsMap(prev => ({
        ...prev,
        [itemTypeId]: newItems.map(item => item.id)
      }));

      // Update items list while preserving all selected items
      setItemsList(prevItemList => {
        const combinedItems = [...prevItemList, ...newItems];
        return combinedItems
          .filter(item =>
            // Keep items if they belong to any of the selected itemTypes
            itemTypeArray.includes(item.itemTypeId) ||
            // Or if they are part of the items to edit
            dataToEdit?.some(editItem => editItem.itemsId === item.id)
          )
          .filter((item, index, self) =>
            // Remove duplicates
            index === self.findIndex(t => t.id === item.id)
          );
      });

      // Ensure all items from dataToEdit are selected in the form
      if (dataToEdit?.length > 0 && formikRef.current) {
        const itemsToSelect = dataToEdit
          .map(data => data.itemsId)
          .filter(Boolean);

        if (itemsToSelect.length > 0) {
          const currentItems = formikRef.current.values.items || [];
          formikRef.current.setFieldValue('items', [
            ...new Set([...currentItems, ...itemsToSelect])
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching items: ", error);
    } finally {
      setLoadingData(false);
    }
  };

  // Add this effect to ensure items are loaded when the component mounts
  useEffect(() => {
    const loadInitialItems = async () => {
      if (!dataToEdit?.length) return;

      const itemTypes = dataToEdit
        .map(item => item?.itemTypeId)
        .filter(item => item !== null);

      if (itemTypes.length > 0) {
        setItemsList([]); // Clear existing items

        // Fetch items for all itemTypes at once
        for (const itemTypeId of itemTypes) {
          await fetchItems(itemTypeId, itemTypes);
        }
      }
    };

    loadInitialItems();
  }, [dataToEdit]);

  // useEffect(() => {
  //   const fetchInitialItems = async () => {
  //     if (!dataToEdit?.length) return;

  //     const itemTypes = dataToEdit
  //       .map((item) => item?.itemTypeId)
  //       .filter((item) => item !== null);

  //     const items = dataToEdit
  //       .map((item) => item?.itemsId)
  //       .filter((item) => item !== null);

  //     if (itemTypes?.length > 0 && items?.length > 0) {
  //       setItemsList([]); // Clear existing items
  //       setLoadingData(true);

  //       try {
  //         for (const itemTypeId of itemTypes) {
  //           const filter = { isDeleted: { ne: true } };
  //           const response = await itemsByItemTypeId(
  //             itemTypeId,
  //             filter,
  //             currentTokenId
  //           );

  //           const newItems = response?.items || [];

  //           // Update itemType-items relationship map
  //           setItemTypeItemsMap(prev => ({
  //             ...prev,
  //             [itemTypeId]: newItems.map(item => item.id)
  //           }));

  //           // Update items list while preserving selected items
  //           setItemsList((prevItemList) => {
  //             const combinedItems = [...prevItemList, ...newItems];
  //             return combinedItems
  //               .filter(item => {
  //                 // Keep items that are either in the selected items array or belong to current itemType
  //                 return items.includes(item.id) || item.itemTypeId === itemTypeId;
  //               })
  //               .filter((item, index, self) =>
  //                 index === self.findIndex((t) => t.id === item.id)
  //               );
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Error fetching initial items:", error);
  //       } finally {
  //         setLoadingData(false);
  //       }
  //     }
  //   };

  //   fetchInitialItems();
  // }, [dataToEdit, currentTokenId]);

  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    validateForm().then((errors) => {
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        setSubmitting(false);
        return;
      }

      // Handle the form submission
      handleSubmitForm(values, formikRef);
    });
  };
  // (handle submit function) :: end
  // useEffect(() => {
  //   if (itemsList?.length === 0) {
  //   }
  // }, [itemsList]);

  useEffect(() => {
    if (selectedItemTypes.length > 0) {
      for (let index = 0; index < selectedItemTypes.length; index++) {
        const element = selectedItemTypes[index];

        fetchItems(element, selectedItemTypes);
      }
    }
  }, [selectedItemTypes]);

  useEffect(() => {
    if (selectedProjects.length > 0) {
      for (let index = 0; index < selectedProjects.length; index++) {
        const element = selectedProjects[index];

        fetchItemTypes(true, element, selectedProjects);
      }
    }
  }, [selectedProjects]);

  // {Fetch function for ItemTypes} :: begins
  const fetchItemTypes = async (
    projectSelected = false,
    projectId,
    selectedProjectsArray
  ) => {
    let filter = { isDeleted: { ne: true } };

    if (projectSelected) {
      setLoadingData(true);
      try {
        if (projectId) {
          const response = await itemTypesByProjectId(
            projectId,
            filter,
            null,
            500,
            currentTokenId
          );

          const newItemTypes =
            response?.data?.itemTypesByProjectId?.items || [];

          // Update project-itemType relationship map
          setProjectItemTypeMap((prev) => ({
            ...prev,
            [projectId]: newItemTypes.map((item) => item.id),
          }));

          setItemTypeList((prevItemTypeList) => {
            const combinedItemTypes = [...prevItemTypeList, ...newItemTypes];
            return combinedItemTypes
              .filter((item) => selectedProjectsArray.includes(item.projectId))
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id)
              );
          });
        }
      } catch (error) {
      } finally {
        setLoadingData(false);
      }
    } else {
      fetchData(
        userOrganizationId,
        listItemsTypesSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken
      );
    }
  };

  // {Fetch function for ItemTypes} :: end

  // const fetchItems = async (itemTypeId, itemTypeArray) => {
  //   setLoadingData(true);
  //   try {
  //     let filter = { isDeleted: { ne: true } };

  //     if (itemTypeId) {
  //       const response = await itemsByItemTypeId(
  //         itemTypeId,
  //         filter,
  //         currentTokenId
  //       );

  //       const newItems = response?.items || [];

  //       // Update itemType-items relationship map
  //       setItemTypeItemsMap(prev => ({
  //         ...prev,
  //         [itemTypeId]: newItems.map(item => item.id)
  //       }));

  //       setItemsList((prevItemList) => {
  //         const combinedItems = [...prevItemList, ...newItems];
  //         return combinedItems
  //           .filter(item => itemTypeArray.includes(item.itemTypeId))
  //           .filter((item, index, self) =>
  //             index === self.findIndex((t) => t.id === item.id)
  //           );
  //       });
  //     }
  //   } catch (error) {
  //     console.log("Error fetching items: ", error);
  //   } finally {
  //     setLoadingData(false);
  //   }
  // };

  // Update project deselection handler
  const handleProjectDeselect = (
    deselectedProjectId,
    setFieldValue,
    values
  ) => {
    const deselectedItemTypes = projectItemTypeMap[deselectedProjectId] || [];
    const remainingItemTypes = values.itemType.filter(
      (itemTypeId) => !deselectedItemTypes.includes(itemTypeId)
    );

    const deselectedItems = deselectedItemTypes.flatMap(
      (itemTypeId) => itemTypeItemsMap[itemTypeId] || []
    );
    const remainingItems = values.items.filter(
      (itemId) => !deselectedItems.includes(itemId)
    );

    setFieldValue("itemType", remainingItemTypes);
    setFieldValue("items", remainingItems);

    setItemTypeList((prev) =>
      prev.filter((itemType) => !deselectedItemTypes.includes(itemType.id))
    );

    setItemsList((prev) =>
      prev.filter((item) => !deselectedItems.includes(item.id))
    );
  };

  // {Delete deselected associate item} :: begin
  const deleteDeselected = (id) => {
    const result = itemTypeList.filter((obj) => obj?.Projects?.id !== id);

    setItemTypeList(result);
  };

  const deleteDeselectedItems = (id) => {
    const result = itemsList.filter((obj) => obj?.itemTypeId !== id);

    setItemsList(result);
  };
  // {Delete deselected associate item} :: end

  useEffect(() => {
    const itemTypes = itemTypesOptions
      ?.map((item) =>
        formikRef?.current?.values.itemType.filter(
          (type) => item.value === type
        )
      )
      .flat();

    setItemTypeValues(itemTypes);

    const items = itemsOptions
      ?.map((item) =>
        formikRef?.current?.values.items.filter((type) => item.value === type)
      )
      .flat();

    setItemsValue(items);
  }, [formikRef?.current?.values?.project, itemTypesOptions]);

  useEffect(() => {
    const items = itemsOptions
      ?.map((item) =>
        formikRef?.current?.values.items.filter((type) => item.value === type)
      )
      .flat();

    setItemsValue(items);
  }, [formikRef?.current?.values?.itemType, itemsOptions]);

  return (
    <Formik
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ touched, handleChange, values, errors, setFieldValue }) => {
        return (
          <Form id={formName}>
            <Row gutter={bulkEdit && 16}>
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="project">Project</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      name="project"
                      mode="multiple"
                      allowClear
                      value={values.project || []}
                      placeholder="Please select project"
                      onSearch={(input) =>
                        handleCustomInputSearch(input, "project")
                      }
                      onChange={(e) => {
                        setFieldValue("project", e);
                        setSelectedProjects(e);
                        if (values?.project?.length === 0) {
                          setItemsList([]);
                          setFieldValue("itemType", []);
                          setFieldValue("items", []);
                        }
                      }}
                      onClear={() => {
                        setSelectedProjects([]);
                        setFieldValue("itemType", []);
                        setFieldValue("items", []);
                        fetchItemTypes(false);
                        fetchItems(false);
                      }}
                      onDeselect={(deselectedProject) => {
                        handleProjectDeselect(
                          deselectedProject,
                          setFieldValue,
                          values
                        );
                      }}
                      loading={loadingData}
                      options={projectOptions}
                      autoFocus
                    />
                  </Space>
                </div>
              </Col>

              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="itemType">Item Type(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="itemType"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      allowClear
                      value={values.itemType ? values.itemType : []}
                      placeholder="Please select item types"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "itemType");
                      }}
                      onChange={(e) => {
                        setFieldValue("itemType", e);
                        setSelectedItemTypes(e);
                      }}
                      onClear={() => {
                        setSelectedItemTypes([]);
                        setFieldValue("items", []);
                        setItemsList([]);
                      }}
                      onSelect={(e) => {
                        // fetchItems(e);
                      }}
                      onDeselect={(e) => {
                        deleteDeselectedItems(e);
                        setFieldValue("items", itemsValue);

                        const filter = values?.itemType?.filter(
                          (item) => item !== e
                        );
                        setItemsList(
                          itemsList?.filter((items) => items?.itemTypeId !== e)
                        );
                        if (filter?.length === 0) {
                          setItemsList([]);
                        }

                        if (values?.itemType?.length === 1) {
                          setFieldValue("items", []);
                        }
                      }}
                      loading={loadingData}
                      options={itemTypesOptions}
                      autoFocus
                    />
                  </Space>
                  {touched?.itemType && (
                    <ErrorMessageComponent error={errors?.itemType} />
                  )}
                </div>
              </Col>
            </Row>

            {actionType === "Item" && (
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="items">Item(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="items"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      allowClear
                      value={values.items ? values.items : []}
                      placeholder="Please select items"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "items");
                      }}
                      onChange={(e) => setFieldValue("items", e)}
                      loading={loadingData}
                      options={itemsOptions}
                      autoFocus
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Space>
                  {touched?.items && (
                    <ErrorMessageComponent error={errors?.items} />
                  )}
                </div>
              </Col>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssociateForm;
