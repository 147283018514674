import { Button, Input, Modal } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";

const NcdotHicamsConfigCreate = (props) => {
  const { configModel, setConfigModel, handleCreateConfig, loading, editData } =
    props;

  const formikRef = useRef();

  const handleSubmit = async (values) => {
    values.apiSource = "idencia";
    values.apiSecurityKey = "{F8F671AB-3FE9-495A-AF18-16C9C22D28C7";
    values.isAvailable = true;
    values.environment = "QA";

    if (editData) {
      values.apiType = editData?.apiType;
    }

    handleCreateConfig(values, formikRef);
  };

  const validationSchema = Yup.object().shape({
    apiSegment: Yup.string().required("Please add API Segment"),
    apiUrl: Yup.string().required("Please add API URL"),
  });

  const initialValues = {
    apiSegment: editData ? editData?.apiSegment : null,
    apiUrl: editData ? editData?.apiUrl : null,
  };

  return (
    <div>
      <Modal
        classNames={"py-10"}
        title="Create Configuration"
        width={350}
        centered
        className="modal"
        open={configModel}
        onCancel={() => {
          setConfigModel(false);
          formikRef?.current?.resetForm();
          //   dataClear && dataClear();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              formikRef?.current?.resetForm();
            }}
          >
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="createConfig"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>,
        ]}
      >
        <Formik
          onSubmit={handleSubmit}
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ touched, handleChange, values, errors, setFieldValue }) => {
            return (
              <Form id="createConfig">
                <div className="mb-10">
                  <label htmlFor="name">API Segment</label>
                  <Field
                    type="text"
                    name="apiSegment"
                    as={Input}
                    placeholder="Enter Segment"
                  />
                  {touched.apiSegment && (
                    <ErrorMessageComponent error={errors?.apiSegment} />
                  )}
                </div>

                <div className="mb-10">
                  <label htmlFor="name">API Url</label>
                  <Field
                    type="text"
                    name="apiUrl"
                    as={Input}
                    placeholder="Enter API Url"
                  />
                  {touched.apiUrl && (
                    <ErrorMessageComponent error={errors?.apiUrl} />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default NcdotHicamsConfigCreate;
