import React, { useRef, useState } from "react";
import { Button, Modal, Input } from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { saveFolderToS3 } from "utils/Helper/commonMethods";
import { CommonError } from "utils/Helper/CommonError";
import { createDocuments } from "utils/Actions";

export default function AddNewFolder(props) {
  const {
    setAddNewFolderModal,
    addNewFolderModal,
    folderKey,
    userOrganizationId,
    currentIdToken,
  } = props;
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (value, ref) => {
    try {
      const input = {
        fileType: "Folder",
        name: value?.name,
        organizationID: userOrganizationId,
        createdAt: new Date(),
        parentId: folderKey,
        fileName: "",
      };

      setLoading(true);
      await createDocuments(input, currentIdToken);
      setAddNewFolderModal(false);
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
      ref?.resetForm();
      setAddNewFolderModal(false);
    }
  };

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter Folder Name")
      .test(
        "no-whitespace",
        "Folder name cannot contain whitespace",
        function (value) {
          return !/\s/.test(value);
        }
      ),
  });

  return (
    <Modal
      title="Add New Folder"
      width={400}
      centered
      className="modal"
      maskClosable={true}
      open={addNewFolderModal}
      onCancel={() => {
        setAddNewFolderModal(false);
      }}
      footer={[
        <Button
          type="primary"
          key={"submit"}
          form="editDocument"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Formik
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ touched, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form id="editDocument">
              <div className="mb-10">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  name="name"
                  as={Input}
                  placeholder="Enter name"
                />

                <ErrorMessageComponent error={errors?.name} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
