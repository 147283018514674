import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectInput = ({
  field,
  form,
  options,
  placeholder,
  isMulti,
  showSearch,
}) => {
  const { name, value } = field;
  const { setFieldValue, setFieldTouched } = form;

  const handleSelectChange = (value) => {
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  return (
    <Select
      name={name}
      value={value}
      mode={isMulti && "multiple"}
      onChange={handleSelectChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch={showSearch}
      placeholder={placeholder}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
