import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Input, TreeSelect, message } from "antd";
import { Formik, Form, Field } from "formik";
import SelectInput from "Common/Components/SelectInput/SelectInput";
import * as Yup from "yup";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { updateDocuments } from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
import {
  moveFileBetweenFoldersInS3,
  renameAndMoveFolder,
} from "utils/Helper/commonMethods";

export default function EditDocuments(props) {
  const {
    setDocumentEditModal,
    documentEditModal,
    documentToEdit,
    currentIdToken,
    handleCustomSubmit = () => { },
    treeData,
    folderArray,
  } = props;

  const [loading, setLoading] = useState(false);
  const [destinationPath, setDestinationPath] = useState("");
  const [folderArr, setFolderArr] = useState([]);
  const formikRef = useRef();

  const handleSubmit = async (value, ref) => {
    if (folderArr?.length > 0) {
      handleFolderSubmit(value, ref);
    } else {
      handleDocumentSubmit(value, ref);
    }
  };

  const handleDocumentSubmit = async (value, ref) => {
    setLoading(true);

    try {
      let sourceKey = documentToEdit[0]?.fileName;
      const fileName = documentToEdit[0]?.fileName?.split("/");

      let destinationKey = `${destinationPath === "" ? "Documents" : destinationPath
        }/${fileName[fileName?.length - 1]}`;

      const input = {
        id: documentToEdit && documentToEdit[0]?.id,
        name: value?.name,
        parentId: value?.folderName === "Documents" ? null : value?.folderName,
        // fileName: destinationKey,
      };
      await updateDocuments(input, currentIdToken);
      await handleCustomSubmit(value);
      // await moveFileBetweenFoldersInS3(sourceKey, destinationKey);

      setDocumentEditModal(false);
      formikRef?.current?.resetForm();
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderSubmit = async (value, ref) => {
    setLoading(true);

    try {
      const sourceFolder = documentToEdit?.folder;
      const destinationFolder = `${value?.folderName === "Documents"
        ? value?.folderName
        : `Documents/${value?.folderName}`
        }/${folderArr[folderArr?.length - 1]}`;
      const newFolderName = value?.name;

      await renameAndMoveFolder(
        sourceFolder,
        destinationFolder,
        newFolderName
      ).then((result) => {
        if (result) {
          message.success("Folder renamed and moved successfully!");
        } else {
          CommonError("Failed to rename and move folder.");
        }
      });

      setDocumentEditModal(false);
      setFolderArr([]);
      setDestinationPath("");
      formikRef?.current?.resetForm();
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };

  let folder =
    documentToEdit && documentToEdit?.folder && folderArr?.length === 2
      ? folderArr[0]
      : folderArr[1];
  useEffect(() => {
    if (documentToEdit && documentToEdit?.folder) {
      setFolderArr(
        documentToEdit &&
        documentToEdit?.folder &&
        documentToEdit?.folder?.split("/")
      );
    }
  }, [documentToEdit]);

  const initialValues = {
    name: documentToEdit ? documentToEdit[0]?.name : "" || "",
    folderName: documentToEdit
      ? documentToEdit[0]?.parentId !== null
        ? documentToEdit[0]?.parentId
        : "Documents"
      : "Documents" || "Documents",
  };

  const validationSchema = Yup.object().shape({
    name:
      documentToEdit && documentToEdit[0]?.fileType === "Folder"
        ? Yup.string()
          .required("Please enter Folder Name")
          .test(
            "no-whitespace",
            "Folder name cannot contain whitespace",
            function (value) {
              return !/\s/.test(value);
            }
          )
        : Yup.string().required("Please enter document name"),
  });

  const disableTreeNodes = (data, initialValues) => {
    return data.map((node) => {
      const isNodeDisabled = initialValues === node.value;
      return {
        ...node,
        disabled: node.value === null ? true : isNodeDisabled,
        children: node.children
          ? disableTreeNodes(node.children, initialValues)
          : [],
      };
    });
  };

  const dynamicTreeData =
    documentToEdit && Array.isArray(treeData)
      ? disableTreeNodes(treeData, initialValues.folderName)
      : [];

  const folderHierarchy = (values, setFieldValue) => {
    if (documentToEdit[0]?.fileType !== "Folder") {
      return (
        <div className="mb-10">
          <label htmlFor="folderName">Move to Folder</label>
          <Field name="folderName">
            {({ field, form }) => (
              <TreeSelect
                value={values.folderName}
                field={field}
                form={form}
                treeData={dynamicTreeData}
                placeholder="Please select"
                treeCheckable={false}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                allowClear
                treeDefaultExpandAll
                labelInValue
                onChange={(valueObj) => {
                  const newValue = valueObj ? valueObj.value : "";

                  const selectedPath = folderArray
                    ?.map((array) => array?.filter((item) => item !== ""))
                    ?.find((array) => array[array.length - 1] === newValue);

                  if (selectedPath) {
                    const pathString = selectedPath.join("/");
                    setDestinationPath(pathString);
                  }

                  setFieldValue("folderName", newValue);
                }}
                onSelect={(value, node) => {
                }}
                treeNodeFilterProp="title"
              />
            )}
          </Field>
        </div>
      );
    }
  };

  return (
    <Modal
      title="Rename Document"
      width={400}
      centered
      className="modal"
      maskClosable={true}
      open={documentEditModal}
      onCancel={() => {
        setFolderArr([]);
        setDocumentEditModal(false);
        formikRef?.current?.resetForm();
      }}
      footer={[
        <Button
          onClick={() => {
            formikRef?.current?.resetForm();
          }}
        >
          Reset
        </Button>,
        <Button
          type="primary"
          // key={"submit"}
          onClick={() => {
            handleSubmit(formikRef?.current?.values);
          }}
          form="editDocument"
          // htmlType="submit"
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Formik
        onSubmit={handleSubmit}
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ touched, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form id="editDocument">
              <div className="mb-10">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  name="name"
                  as={Input}
                  placeholder="Enter name"
                />
                <ErrorMessageComponent error={errors?.name} />
              </div>

              {folderHierarchy(values, setFieldValue)}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
