import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Modal, Button, message } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { Auth } from "aws-amplify";
import "./../../custom.less";
import { CommonError } from "utils/Helper/CommonError";

const ChangePassword = (props) => {
  const formikRef = useRef();
  const { setItemModal, itemModal } = props;

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string().required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });
  // const success = () => {
  //   Modal.success({
  //     content: (
  //       <Row justify="center" align="middle">
  //         <Col span={24} className="password-changed">
  //           {/* <img src={PasswordChanged} alt="" /> */}
  //           <Typography.Title level={4} className="mt-10 mb-5 fw-600">
  //             Password Changed
  //           </Typography.Title>
  //           <Typography.Title level={5} className="mt-0 fs-15 text-gray">
  //             Your password has been changed successfully.
  //           </Typography.Title>
  //         </Col>
  //       </Row>
  //     ),
  //     okText: "Close",
  //     icon: <></>,
  //     centered: true,
  //     width: 500,
  //   });
  // };
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const handleSubmit = async (values) => {
    // Handle form submission here
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { confirmPassword, currentPassword, newPassword } = values;
      if (confirmPassword !== newPassword) {
        throw new Error("Confirm New Password does not match");
      }
      await Auth.changePassword(cognitoUser, currentPassword, newPassword);
      message.success("Password update successfully!");
      formikRef?.current?.resetForm();
      setItemModal(false);
    } catch (error) {
      if (error?.message === "Incorrect username or password.") {
        error.message = "Current Password is incorrect";
      }
      CommonError(error);
    }
  };

  return (
    <Modal
      title="Change Password"
      width={350}
      centered
      className="modal"
      open={itemModal}
      onCancel={() => {
        formikRef?.current?.resetForm();
        setItemModal(false);
      }}
      footer={[
        <Button key="cancel" onClick={() => formikRef?.current?.resetForm()}>
          Reset
        </Button>,
        <Button key="submit" type="primary" form="myForm" htmlType="submit">
          Change Password
        </Button>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldTouched, handleChange, values, errors, setFieldValue }) => (
          <Form id="myForm" className="changeForm">
            <div className="mb-10 ">
              <label htmlFor="currentPassword">Old password</label>
              <Field
                type={"password"}
                name="currentPassword"
                as={Input.Password} // Use Input.Password for password fields
                placeholder="Enter old password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                } // Eye icons for password visibility
              />

              <ErrorMessageComponent error={errors?.currentPassword} />
            </div>
            <div className="mb-10">
              <label htmlFor="newPassword">New password</label>
              <Field
                type={"password"}
                name="newPassword"
                as={Input.Password} // Use Input.Password for password fields
                placeholder="Enter new password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                } // Eye icons for password visibility
              />
              <ErrorMessageComponent error={errors?.newPassword} />
            </div>
            <div className="mb-10">
              <label htmlFor="confirmPassword">Confirm password</label>
              <Field
                type={"password"}
                name="confirmPassword"
                as={Input.Password} // Use Input.Password for password fields
                placeholder="Confirm new password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                } // Eye icons for password visibility
              />
              <ErrorMessageComponent error={errors?.confirmPassword} />
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
