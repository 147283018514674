// React imports
import React from "react";

/**
 * FullPageLayout component
 * @param {Object} props - The component's props.
 * @param {React.ReactNode} props.children - The children to render within the layout.
 * @return {JSX.Element} The FullPageLayout component.
 */
function FullPageLayout({ children }) {
  return <main className="main text-muted">{children}</main>;
}

export default FullPageLayout;
