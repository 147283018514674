/* eslint-disable */
import { API } from "aws-amplify";
import * as mutationsCustom from "../graphql/customMutation";
import * as queriesCustom from "../graphql/customQueris";
import * as mutationsdefault from "../graphql/mutations";
import * as queriesdefault from "../graphql/queries";

const queries = { ...queriesdefault, ...queriesCustom };
const mutations = { ...mutationsdefault, ...mutationsCustom };

// Global functions created to create functions dynamically and make it reusable
export const ExecuteQuery = (
  query,
  primarObj = {},
  inputData,
  filter,
  nextToken,
  limit
) => {
  return ((input = inputData) => {
    return API.graphql({
      query: queries[query],
      variables: { input, limit, filter, nextToken, ...primarObj },
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const ExecuteMutation = (query, inputData, filter) => {
  return ((input = inputData) => {
    return API.graphql({
      query: mutations[query],
      variables: { input, filter },
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const ExecuteMutationLambda = (query, inputData, filter, authToken) => {
  return ((input = inputData, Token = authToken) => {
    const organizationID =
      inputData?.organizationID || inputData?.organizationId;
    return API.graphql({
      query: mutations[query],
      variables: { input, filter, organizationID },
      authMode: "AWS_LAMBDA",
      authToken: `Token ${Token}`,
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const ExecuteQueryLambda = (
  query,
  primarObj = {},
  inputData,
  filter = {},
  nextToken = null,
  authToken,
  limit
) => {
  return ((input = inputData, Token = authToken) => {
    return API.graphql({
      query: queries[query],
      variables: { input, filter, nextToken, ...primarObj, limit },
      authMode: "AWS_LAMBDA",
      authToken: `Token ${Token}`,
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const ExecuteQueryLambdaFront = (
  query,
  filter,
  sort,
  limit,
  nextToken,
  currentIdToken
) => {
  return ((Token = currentIdToken) => {
    return API.graphql({
      query: queries[query],
      variables: { sort, filter, nextToken, limit },
      authMode: "AWS_LAMBDA",
      authToken: `Token ${Token}`,
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
/**
 *
 * @param {*} query
 * @param {*} input
 * @param {*} id
 * @param {*} filter
 * @param {*} nextTokenParam
 * @param {*} data
 * @param {*} limit
 * @returns
 */

function fetchQueryName(query) {
  let customQueryName = query;
  if (String(query).endsWith("Custom")) {
    customQueryName = query.split("Custom")[0];
  }
  if (String(query).endsWith("ForCount")) {
    customQueryName = query.split("ForCount")[0];
  }
  return customQueryName;
}
