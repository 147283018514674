import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, Modal, Button, Checkbox, Row, Col, Select } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { IdenciaImages } from "Assets/Images";
import { cloneDeep } from "lodash";
import { CustomPropertyAddType, CustomPropertyType } from "utils/Constants";
import { listItemsTypesSearch } from "utils/RESTApi";
import { CommonError } from "utils/Helper/CommonError";
import { useSelector } from "react-redux";
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice";
import { debounceFn } from "utils/Helper/commonMethods";
import { listUserOfOrganizationCustomProperties } from "utils/Actions";
const { Option } = Select;

const StrengthTestEdit = (props) => {
  const {
    setStrengthModal,
    strengthModal,
    settingToEdit,
    loading,
    strenghtSubmit,
    dataClear,
  } = props;
  const currentIdToken = useSelector(getCurrentToken);
  const formikRef = useRef();
  const [loadings, setLoading] = useState(false);
  const [itemTtypes, setItemTypes] = useState([]);
  const userOrganizationId = useSelector(getOrganizationId);
  const [customPropertiesData, setCustomPorpertiesData] = useState([]);
  const { AddItems, removeItems } = IdenciaImages.Items;
  const validationSchema = Yup.object().shape({
    // projectNumber: Yup.string().required("Number is required"),
    itemTypeId: Yup.string().required("Item type is required"),
    title: Yup.string().required("Title is required"),
    labNamePropertyId: Yup.string().required("Lab name is required"),
    diameterPropertyId: Yup.string().required("Diameter is required"),
    expectedStrengthPropertyId: Yup.string().required(
      "Design strength is required"
    ),
    strippingStrengthPropertyId: Yup.string().required("Stripping is required"),
    lengthPropertyId: Yup.string().required("Length is required"),
    pourDatePropertyId: Yup.string().required("Pour date is required"),
    ageToBreaksString: Yup.array().of(
      Yup.object().shape({
        day: Yup.string().required("Day is required"),
        count: Yup.string().required("Count is required"),
      })
    ),
  });
  useEffect(() => {
    fetchMyAPI(userOrganizationId);
    fetchMyAPICustomProperties(userOrganizationId);
  }, [settingToEdit, strengthModal]);
  const fetchMyAPI = async (id, search) => {
    setLoading(true);
    if (strengthModal)
      try {
        let params = {
          organizationId: id,
          keyword: search,
          limit: 20,
        };

        const responseItems = await listItemsTypesSearch(params);

        setItemTypes(responseItems?.data?.data);
      } catch (err) {
        CommonError(err);
      } finally {
        setLoading(false);
      }
  };

  const fetchMyAPICustomProperties = async (id, search) => {
    if (strengthModal)
      try {
        let data = {
          isDeleted: { ne: true },
          name: { contains: search ? search : "" },
          propertyOf: { eq: CustomPropertyType?.items },
        };
        const response = await listUserOfOrganizationCustomProperties(
          id,
          data,
          currentIdToken
        );
        setCustomPorpertiesData(response?.items);
      } catch (err) {
        CommonError(err);
        setLoading(false);
      } finally {
        setLoading(false);
      }
  };
  const initialValues = {
    projectNumber: settingToEdit?.id ? settingToEdit?.projectNumber : "",
    itemTypeId: settingToEdit?.itemTypeId ? settingToEdit?.itemTypeId : null,
    title: settingToEdit?.id ? settingToEdit?.title : null,
    isEnabled: settingToEdit?.id ? settingToEdit?.isEnabled : true,
    userMerticUnits: settingToEdit?.id ? settingToEdit?.userMerticUnits : false,
    labNamePropertyId: settingToEdit?.labNamePropertyId
      ? settingToEdit?.labNamePropertyId
      : null,
    diameterPropertyId: settingToEdit?.diameterPropertyId
      ? settingToEdit?.diameterPropertyId
      : null,
    lengthPropertyId: settingToEdit?.diameterPropertyId
      ? settingToEdit?.lengthPropertyId
      : null,
    expectedStrengthPropertyId: settingToEdit?.expectedStrengthPropertyId
      ? settingToEdit?.expectedStrengthPropertyId
      : null,
    strippingStrengthPropertyId: settingToEdit?.strippingStrengthPropertyId
      ? settingToEdit?.strippingStrengthPropertyId
      : null,
    pourDatePropertyId: settingToEdit?.pourDatePropertyId
      ? settingToEdit?.pourDatePropertyId
      : null,
    ageToBreaksString: settingToEdit?.ageToBreaksString
      ? settingToEdit?.ageToBreaksString?.split(";").map((pair) => {
          const [day, count] = pair.split(" ");
          return { day, count };
        })
      : [{ day: null, count: null }],
  };
  const options = Array.from({ length: 10 }, (_, index) => index + 1);

  const handleSubmit = async (values) => {
    if (settingToEdit?.id) values.id = settingToEdit?.id;
    let clonedata = cloneDeep(values);
    clonedata.ageToBreaksString = values.ageToBreaksString
      ?.map((item) => `${item.day} ${item.count}`)
      .join(";");

    strenghtSubmit(clonedata, formikRef);
  };
  const handleInputSearch = (searchText, isItems) => {
    debouncedHandleInput(searchText, isItems);
  };
  const handleSearch = (value, isItems) => {
    if (isItems) {
      fetchMyAPI(userOrganizationId, value); // You may also want to debounce the search to avoid unnecessary API calls
    } else {
      fetchMyAPICustomProperties(userOrganizationId, value);
    }
    // Implement a debounce function if needed
  };
  const debouncedHandleInput = debounceFn(handleSearch, 500);
  const itemTypesOptions = itemTtypes?.map((items) => ({
    value: items.id,
    label: items.name,
    type: items?.type,
  }));

  const propertiesOptions = customPropertiesData?.map((items) => ({
    value: items.id,
    label: items.name,
    type: items?.type,
  }));

  return (
    <Modal
      title={!settingToEdit ? "New Strength Test" : "Edit Strength Test"}
      width={350}
      centered
      className="modal"
      open={strengthModal}
      maskClosable={true}
      onCancel={() => {
        formikRef?.current?.resetForm();
        setStrengthModal();
        dataClear && dataClear();
      }}
      footer={[
        <Button key="cancel" onClick={() => formikRef?.current?.resetForm()}>
          Reset
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="strengthForm"
          htmlType="submit"
          loading={loading}
        >
          {settingToEdit?.id ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form id="strengthForm">
            <div className="mb-10">
              <Checkbox
                name="isEnabled"
                checked={values?.isEnabled}
                value={values?.isEnabled}
                onChange={handleChange}
              >
                Enable
              </Checkbox>
              <Checkbox
                name="userMerticUnits"
                checked={values?.userMerticUnits}
                value={values?.userMerticUnits}
                onChange={handleChange}
              >
                Use Metric Units
              </Checkbox>
            </div>

            <div className="mb-10">
              <label htmlFor="projectNumber">Project Number</label>
              <Field
                name="projectNumber"
                as={Input}
                type="number"
                placeholder="Enter number"
              ></Field>
              {/* {touched?.projectNumber && (
                <ErrorMessageComponent error={errors?.projectNumber} />
              )} */}
            </div>

            <div className="mb-10">
              <label htmlFor="itemTypeId">Item Type</label>
              <Field name="itemTypeId">
                {({ field, form, meta }) => (
                  <Select
                    disabled={
                      settingToEdit?.id && values?.itemTypeId ? true : false
                    }
                    name="itemTypeId"
                    value={
                      settingToEdit
                        ? settingToEdit?.ItemTypes?.name
                        : values?.itemTypeId || null
                    }
                    showSearch
                    filterOption={false} // Disable client-side filtering
                    loading={loadings} // Pass loading state to the Select component
                    onChange={async (e) => {
                      setFieldValue("itemTypeId", e);
                      // const foundRecord = await itemTtypes?.find(
                      //   (item) => item.id === e
                      // );
                      // setFieldValue("processId", foundRecord?.Processes?.id);
                      // setFieldError("itemTypeId", ""); // setSelectedCustomPropertyId(e);
                    }}
                    onSearch={async (input) => {
                      handleInputSearch(input, true);
                    }}
                    placeholder="Select item type"
                  >
                    {itemTypesOptions?.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Field>
              {touched.itemTypeId && (
                <ErrorMessageComponent error={errors?.itemTypeId} />
              )}
            </div>

            <div className="mb-10">
              <label htmlFor="title">Title</label>
              <Field name="title" as={Input} placeholder="Enter title"></Field>
              {touched.title && <ErrorMessageComponent error={errors?.title} />}
            </div>

            <div className="mb-10">
              <label htmlFor="labNamePropertyId">
                Lab Name Custom Property
              </label>
              <Select
                name="labNamePropertyId"
                value={values?.labNamePropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("labNamePropertyId", e);
                  // const foundRecord = await itemTtypes?.find(
                  //   (item) => item.id === e
                  // );
                  // setFieldValue("processId", foundRecord?.Processes?.id);
                  // setFieldError("itemTypeId", ""); // setSelectedCustomPropertyId(e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.text
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>

              {touched.labNamePropertyId && (
                <ErrorMessageComponent error={errors?.labNamePropertyId} />
              )}
            </div>
            <div className="mb-10">
              <label htmlFor="diameterPropertyId">
                Diameter Custom Property
              </label>
              <Select
                name="diameterPropertyId"
                value={values?.diameterPropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("diameterPropertyId", e);
                  // const foundRecord = await itemTtypes?.find(
                  //   (item) => item.id === e
                  // );
                  // setFieldValue("processId", foundRecord?.Processes?.id);
                  // setFieldError("itemTypeId", ""); // setSelectedCustomPropertyId(e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.number
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
              {touched.diameterPropertyId && (
                <ErrorMessageComponent error={errors?.diameterPropertyId} />
              )}{" "}
            </div>
            <div className="mb-10">
              <label htmlFor="lengthPropertyId">Length Custom Property</label>
              <Select
                name="lengthPropertyId"
                value={values?.lengthPropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("lengthPropertyId", e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.number
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
              {touched.lengthPropertyId && (
                <ErrorMessageComponent error={errors?.lengthPropertyId} />
              )}
            </div>
            <div className="mb-10">
              <label htmlFor="expectedStrengthPropertyId">
                Design Strength Custom Property
              </label>
              <Select
                name="expectedStrengthPropertyId"
                value={values?.expectedStrengthPropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("expectedStrengthPropertyId", e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.number
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
              {touched.expectedStrengthPropertyId && (
                <ErrorMessageComponent
                  error={errors?.expectedStrengthPropertyId}
                />
              )}{" "}
            </div>
            <div className="mb-10">
              <label htmlFor="strippingStrengthPropertyId">
                Stripping Strength Custom Property
              </label>
              <Select
                name="strippingStrengthPropertyId"
                value={values?.strippingStrengthPropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("strippingStrengthPropertyId", e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.number
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
              {touched.strippingStrengthPropertyId && (
                <ErrorMessageComponent
                  error={errors?.strippingStrengthPropertyId}
                />
              )}{" "}
            </div>
            <div className="mb-10">
              <label htmlFor="pourDatePropertyId">
                Pour Date Custom Property
              </label>
              <Select
                name="pourDatePropertyId"
                value={values?.pourDatePropertyId || null}
                showSearch
                filterOption={false} // Disable client-side filtering
                loading={loadings} // Pass loading state to the Select component
                onChange={async (e) => {
                  setFieldValue("pourDatePropertyId", e);
                }}
                onSearch={async (input) => {
                  handleInputSearch(input, false);
                }}
                placeholder="Select Property"
              >
                {propertiesOptions
                  ?.filter(
                    (option) => option.type === CustomPropertyAddType?.date
                  )
                  .map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
              </Select>
              {touched.pourDatePropertyId && (
                <ErrorMessageComponent error={errors?.pourDatePropertyId} />
              )}{" "}
            </div>

            <FieldArray name="ageToBreaksString">
              {({ remove, push }) => (
                <div>
                  <label htmlFor="ageToBreaksString">Age to break</label>
                  <Row
                    align="middle"
                    justify="center"
                    className="p-10 fw-500"
                    gutter={[10, 0]}
                  >
                    <Col span={2}>#</Col>
                    <Col span={11} className="pl-15">
                      Day
                    </Col>
                    <Col span={11} className="pl-15">
                      Count
                    </Col>
                  </Row>
                  {values?.ageToBreaksString?.map((p, index) => {
                    return (
                      <div key={p.id}>
                        <Row
                          align="middle"
                          justify="center"
                          className="plr-10 mb-10"
                          gutter={[10, 0]}
                        >
                          <Col span={2} className="fw-bold">
                            {index + 1}
                          </Col>
                          <Col span={10}>
                            <Field
                              name={`ageToBreaksString[${index}].day`}
                              as={Input}
                              type="number"
                              placeholder="Enter day"
                            ></Field>
                            <ErrorMessage
                              name={`ageToBreaksString[${index}].day`}
                              component="div"
                              className="error-class"
                            />
                          </Col>
                          <Col span={10}>
                            <Select
                              name={`ageToBreaksString[${index}].count`}
                              value={
                                values?.ageToBreaksString[index].count || null
                              }
                              onChange={async (e) => {
                                setFieldValue(
                                  `ageToBreaksString[${index}].count`,
                                  e
                                );
                              }}
                              placeholder="Select Property"
                            >
                              {options.map((number) => (
                                <Option key={number} value={number}>
                                  {number}
                                </Option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name={`ageToBreaksString[${index}].count`}
                              component="div"
                              className="error-class"
                            />
                          </Col>
                          <Col span={2} lg={2} md={24} xs={24} sm={24}>
                            <span
                              onClick={async (e) => remove(index)}
                              className="cursor-pointer"
                            >
                              <img src={removeItems} alt="removeItems" />
                            </span>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Button
                    type="button"
                    className="addbutton"
                    onClick={() =>
                      push({
                        day: null,
                        count: null,
                      })
                    }
                    icon={<img src={AddItems} alt="removeItems" />}
                  >
                    Add New Break
                  </Button>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default StrengthTestEdit;
