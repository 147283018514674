import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Row, Button, Grid, Radio, Typography, message, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "../../Setup/Setup.less";
import TableSkeleton from "Common/Components/Skeleton/TableSkeleton";
import SearchableTable from "Common/Components/DataTable/DataTable";
import { IdenciaImages } from "Assets/Images";
import NcdotHicamsEdit from "./NcdotHicamsEdit/NcdotHicamsEdit";
import { getModuleAccess } from "store/slices/moduleAccessSlice";
import { useSelector } from "react-redux";
import LogsDrawer from "../ForneyVault/LogsDrawer";
import {
  getCurrentOrganization,
  getOrganizationId,
  getCurrentUser,
  getCurrentIdToken,
  getCurrentToken,
} from "store/slices/loginSlice";
import {
  createHiCamsConfigurations,
  createHiCamsSettings,
  customersByOrganizationID,
  hiCamsConfigurationsByOrganizationID,
  hiCamsLogsByOrganizationID,
  hiCamsSettingsByOrganizationID,
  searchHiCamsLogs,
  updateHiCamsSettings,
} from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
import {
  listCustomPropertiesSearch,
  listItemsTypesSearch,
  resendAPI,
  triggerAPI,
} from "utils/RESTApi";
import { CustomPropertyType, displayTimeFormatWithTIme } from "utils/Constants";
import NcdotHicamsConfigCreate from "./NcdotHicamsConfigCreate";
import { toUpper } from "lodash";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import { dateTimeConversionBasedOnTimeZone } from "utils/Helper/commonMethods";

export default function NcdotHicams() {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [itemModal, setItemModal] = useState(false);
  const [activeTab, setActiveTab] = useState("settings");
  const [openLogsDrawer, setOpenLogsDrawer] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [hiCamsLogs, setHiCamsLogs] = useState([]);
  const [hiCamsConfig, setHiCamsConfig] = useState([]);
  const [drawerData, setDrawerData] = useState([]);
  const [customerlist, setCustomerlist] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [customItem, setCustomItem] = useState([]);
  const [customItemType, setCustomItemType] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [confEditData, setConfEditData] = useState([]);
  const [triggerLoading, setTriggerLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const currentOrganization = useSelector(getCurrentOrganization);
  const userOrganizationId = useSelector(getOrganizationId);
  const currentUser = useSelector(getCurrentUser);
  const currentIdToken = useSelector(getCurrentIdToken);
  const { openrIcon } = IdenciaImages.General;
  const currentIAuthToken = useSelector(getCurrentToken);

  //Role based module access
  const { hasReadAccesss, hasUpdateAccess } = useSelector((state) =>
    getModuleAccess(state, "NcdotHicams")
  );

  // fetch settings :: begin
  const fetchSettings = async () => {
    setLoadingTable(true);

    try {
      const response = await hiCamsSettingsByOrganizationID(
        userOrganizationId,
        null,
        currentIdToken
      );

      setSettingsData(response?.items[0]);
    } catch (error) {
    } finally {
      setLoadingTable(false);
    }
  };
  // fetch settings :: end

  // fetch logs :: begins
  const fetchLogs = async (id, nextTokenData, sortBy) => {
    if (nextTokenData === null) {
      setLoadingTable(true);
    }
    try {
      let processFilter = {
        // isDeleted: { ne: true },
        // isActive: { eq: true },
        organizationID: { eq: id },
      };

      const sort = {
        field: "createdAt",
        direction: sortBy,
      };
      const processresponse = await searchHiCamsLogs(
        processFilter,
        sort,
        20,
        nextTokenData,
        currentIdToken
      );

      setNextToken(processresponse?.nextToken);

      if (!nextTokenData) {
        setHiCamsLogs(() => [...processresponse?.items]);
      } else {
        setHiCamsLogs((prevData) => [...prevData, ...processresponse?.items]);
      }
    } catch (error) {
    } finally {
      setScrollLoading(false);
      setLoadingTable(false);
    }
  };
  // fetch logs :: end

  // set scroll on page end :: begins
  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextToken) {
      setScrollLoading(true);
      fetchLogs(userOrganizationId, nextToken, sortOrder);
    }
  };
  // set scroll on page end :: end

  // fetch configurations :: begins
  const fetchConfigurations = async () => {
    setLoadingTable(true);

    try {
      const responseConfiguration = await hiCamsConfigurationsByOrganizationID(
        userOrganizationId,
        null,
        currentIdToken
      );

      setHiCamsConfig(responseConfiguration);
    } catch (error) {
    } finally {
      setLoadingTable(false);
    }
  };
  // fetch configurations :: end

  // fetch data :: begins
  useEffect(() => {
    if (activeTab === "settings") {
      fetchSettings();
    } else if (activeTab === "logs") {
      fetchLogs(userOrganizationId, nextToken, sortOrder);
    } else {
      fetchConfigurations();
    }
  }, [activeTab]);
  // fetch data :: end

  // fetch custom data :: begin
  const fetchMyAPI = useCallback(async (id, search) => {
    let filter = {
      isDeleted: { ne: true },
    };

    let params = {
      organizationId: id,
      keyword: search,
      limit: 20,
    };

    setLoadingData(true);
    try {
      const response = await customersByOrganizationID(
        id,
        filter,
        currentIdToken
      );
      setCustomerlist(response?.items);

      const responseItems = await listItemsTypesSearch(params);
      setItemTypes(responseItems?.data?.data);
    } catch (err) {
      CommonError(err);
    } finally {
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    fetchMyAPI(userOrganizationId);
  }, [itemModal]);

  const fetchCustomData = async (id, property, search) => {
    setLoadingData(true);
    try {
      const params = {
        organizationId: id,
        keyword: search,
        limit: 20,
        propertyOf: property,
      };

      const response = await listCustomPropertiesSearch(params);
      if (property === 0) {
        setCustomItem(response?.data?.data);
      } else {
        setCustomItemType(response?.data?.data);
      }
    } catch (err) {
      CommonError(err);
      setLoadingData(false);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchCustomData(userOrganizationId, CustomPropertyType.items);
    fetchCustomData(userOrganizationId, CustomPropertyType.itemTypes);
  }, []);
  // fetch custom data :: end

  // producer options :: begins
  const producerOptions = [
    { value: 0, label: "Precast" },
    { value: 1, label: "Pre Stressed" },
    { value: 2, label: "Both" },
  ];
  // producer options :: begins

  // configurations table data :: begins
  const ncdoConfData = useMemo(() => {
    let configData = hiCamsConfig?.items
      ?.filter((item) => item.apiType === "POST")
      ?.map((item, index) => {
        return {
          key: index,
          Property:
            item?.apiSegment === "PCASTRFIDTags"
              ? "Precast Tags Url"
              : item?.apiSegment === "PCASTTestResults"
              ? "Precast Test Result Url"
              : item?.apiSegment === "PSTRSRFIDTags"
              ? "Pre-stressed Tags Url"
              : "Pre-stressed Test Result Url",
          Value: item?.apiUrl,
          Action: (
            <>
              <Tooltip title="Edit" placement="bottom">
                <Button
                  type="link"
                  onClick={() => {
                    setConfEditData(item);
                    setItemModal(true);
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </>
          ),
        };
      });

    return configData;
  }, [hiCamsConfig]);
  // configurations table data :: begins

  // settings table data :: begins
  const ncdotHicamsData = useMemo(() => {
    let row = [
      {
        key: "1",
        Property: "Enable",
        Value: settingsData?.isEnabled && <img src={openrIcon} alt="close" />,
      },
      {
        key: "2",
        Property: "Restrict By Customer",
        Value: settingsData?.restrictedByCustomer && (
          <img src={openrIcon} alt="close" />
        ),
      },
      {
        key: "3",
        Property: "Restrict To Customer",
        Value: settingsData?.Customers?.name,
      },
      {
        key: "4",
        Property: "Producer",
        Value:
          settingsData &&
          producerOptions.find(
            (item) => item.value === settingsData?.producerType
          )?.label,
      },
    ];

    let conditionalRows = [];

    if (settingsData?.producerType === 0 || settingsData?.producerType === 2) {
      conditionalRows.push(
        {
          key: "5",
          Property: "Precast Item Type",
          Value: settingsData?.ItemType?.name,
        },
        {
          key: "15",
          Property: "Pour Number Property (Item)",
          Value: settingsData?.PourNumberProperty?.name,
        },
        {
          key: "16",
          Property: "Air Content Property (Item)",
          Value: settingsData?.AirContentProperty?.name,
        },
        {
          key: "17",
          Property: "Slump Property (Item)",
          Value: settingsData?.SlumpProperty?.name,
        },
        {
          key: "18",
          Property: "Spread Property (Item)",
          Value: settingsData?.SpreadProperty?.name,
        }
      );
    }

    if (settingsData?.producerType === 1 || settingsData?.producerType === 2) {
      conditionalRows.push(
        {
          key: "6",
          Property: "Pre Stressed Item Type",
          Value: settingsData?.PreStressedItemType?.name,
        },
        {
          key: "11",
          Property: "Qty Property (Item Type)",
          Value: settingsData?.QtyProperty?.name,
        },
        {
          key: "12",
          Property: "Contract Number Property (Item Type)",
          Value: settingsData?.ContractNumberProperty?.name,
        },
        {
          key: "20",
          Property: "Bed Number Property (Item)",
          Value: settingsData?.BedNumberProperty?.name,
        },
        {
          key: "21",
          Property: "Standard Strength Ind Property (Item)",
          Value: settingsData?.StandardStrengthIndProperty?.name,
        },
        {
          key: "22",
          Property: "Neoprene Cap Ind Property (Item)",
          Value: settingsData?.NeopreneCapIndProperty?.name,
        },
        {
          key: "23",
          Property: "Cylinder Diameter Property (Item)",
          Value: settingsData?.CylinderDiameterProperty?.name,
        }
      );
    }

    let remainingRows = [
      {
        key: "7",
        Property: "Plant ID Property (Item Type)",
        Value: settingsData?.PlantIdPropertyId?.name,
      },
      {
        key: "8",
        Property: "Material Description Property (Item Type)",
        Value: settingsData?.MaterialDescProperty?.name,
      },
      {
        key: "9",
        Property: "Material ID Property (Item Type)",
        Value: settingsData?.MaterialIdProperty?.name,
      },
      {
        key: "10",
        Property: "Material Type ID Property (Item Type)",
        Value: settingsData?.MaterialTypeIdProperty?.name,
      },

      {
        key: "13",
        Property: "Concrete Mix Property (Item)",
        Value: settingsData?.ConcreteMixProperty?.name,
      },
      {
        key: "14",
        Property: "Pour Date Property (Item)",
        Value: settingsData?.PourDateProperty?.name,
      },

      {
        key: "19",
        Property: "Flow - J Ring Property (Item)",
        Value: settingsData?.FlowJRingProperty?.name,
      },
    ];

    const allRows = [...row, ...conditionalRows, ...remainingRows];
    const sortedRows = allRows.sort(
      (a, b) => parseInt(a.key) - parseInt(b.key)
    );

    return sortedRows;
  }, [settingsData]);
  // settings table data :: end

  // settings table columns :: begins
  const columns = [
    {
      title: "Property",
      dataIndex: "Property",
      key: "Property",
      sorter: (a, b) => a.Property.localeCompare(b.Property),
      width: "30%",
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
      width: "70%",
    },
  ];
  // settings table columns :: end

  // configuration table columns :: begins
  const configurationColumns = [
    {
      title: "Property",
      dataIndex: "Property",
      key: "Property",
      sorter: (a, b) => a.Property.localeCompare(b.Property),
      width: "30%",
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "20%",
    },
  ];
  // configuration table columns :: end

  // logs table data :: begins
  const ncdotLogData = useMemo(() => {
    const logsTableData = hiCamsLogs.map((item, index) => {
      return {
        key: index,
        // Date: new Date(item?.createdAt)
        //   .toLocaleString("en-US", {
        //     month: "2-digit",
        //     day: "2-digit",
        //     year: "numeric",
        //     hour: "2-digit",
        //     minute: "2-digit",
        //     second: "2-digit",
        //     hour12: true,
        //   })
        //   .replace(",", ""),
        Date: dateTimeConversionBasedOnTimeZone(
          item?.createdAt,
          displayTimeFormatWithTIme,
          currentOrganization.appTimeZone
        ),
        Type:
          item?.producerType === 0
            ? "Precast"
            : item?.producerType === 1
            ? "Pre-stressed"
            : "Both",
        Category: item?.apiEndPoint,
        SerialNumber: item?.Items?.serialNumber,
        Method: toUpper(item?.httpMethodType),
        Status: item?.status,
        Resend: (
          <Typography.Link
            className="text-primary"
            onClick={() => {
              setOpenLogsDrawer(true);
              setDrawerData(item);
            }}
          >
            View
          </Typography.Link>
        ),
      };
    });

    return logsTableData;
  }, [hiCamsLogs]);

  const logColumn = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder: sortOrder === "asc" ? "ascend" : "descend",
      sorter: (a, b) => {
        const dateA = new Date(a?.Date);
        const dateB = new Date(b?.Date);

        // Compare the dates for sorting
        return dateA - dateB;
      },
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      // sorter: (a, b) => a?.Type.localeCompare(b?.Type),
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Serial Number",
      dataIndex: "SerialNumber",
      key: "SerialNumber",
    },
    {
      title: "Method",
      dataIndex: "Method",
      key: "Method",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Resend",
      dataIndex: "Resend",
      key: "Resend",
    },
  ];
  // logs table data :: end

  const DataClear = () => {
    setOpenLogsDrawer(false);
  };

  // handle submit function for settings edit :: begins
  const handleEditSettings = async (values, formikRef) => {
    setLoading(true);
    values.modifiedBy = currentUser?.sub;
    values.organizationID = userOrganizationId;

    if (settingsData?.id) {
      values.id = settingsData?.id;
    }

    try {
      settingsData?.id
        ? updateHiCamsSettings(values, currentIdToken)
        : createHiCamsSettings(values, currentIdToken);
      setItemModal(false);
      message.success("Settings successfully updated");
      formikRef?.current?.resetForm();
    } catch (err) {
      CommonError("Error: ", err);
    } finally {
      fetchSettings();
      setLoading(false);
    }
  };
  // handle submit function for settings edit :: end

  useEffect(() => {
    if (!itemModal) {
      setTimeout(() => {
        if (activeTab === "settings") {
          fetchSettings();
        } else if (activeTab === "configurations") {
          fetchConfigurations();
        } else {
          fetchLogs(userOrganizationId, nextToken, sortOrder);
        }
      }, 500);
    }
  }, [itemModal]);

  // clear data from settings edit form :: begins
  const actionDataClear = () => {
    setItemModal(false);
    setLoading(false);
  };
  // clear data from settings edit form :: end

  // handle tab change :: begins
  const handleTabChange = (e) => {
    setActiveTab(e.target.value);
  };
  // handle tab change :: end

  // config create data :: begin
  const handleCreateConfig = async (value, formikRef, type) => {
    value.organizationID = userOrganizationId;
    value.modifiedBy = currentUser?.sub;

    if (confEditData?.length === 0) {
      value.apiType = type;
    }

    try {
      await createHiCamsConfigurations(value, currentIdToken);
      setItemModal(false);
      message.success("Settings successfully updated");
      formikRef?.current?.resetForm();
    } catch (error) {
      CommonError("Error: ", error);
    } finally {
      setItemModal(false);
      fetchConfigurations();
    }
  };
  // config create data :: end

  // handle change for sorting :: begins
  const handleChange = async (pagination, sortData) => {
    let sortBy = sortData.order === "ascend" ? "asc" : "desc";
    setNextToken(null);
    setSortOrder(sortBy);
  };

  useEffect(() => {
    fetchLogs(userOrganizationId, null, sortOrder);
  }, [sortOrder]);
  // handle change for sorting :: end

  // "trigger logs" :: begins
  const triggerLogs = async () => {
    const params = {
      organizationId: userOrganizationId,
    };
    setTriggerLoading(true);
    setLoading(true);

    try {
      const response = await triggerAPI(params);

      if (response?.status === 200) {
        fetchLogs(userOrganizationId, nextToken, sortOrder);
      }
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
      setTriggerLoading(false);
    }
  };

  const resendLogs = async () => {
    const params = {
      id: drawerData?.id,
    };

    setResendLoading(true);
    setLoading(true);

    try {
      const response = await resendAPI(params);

      if (response?.status === "success") {
        fetchLogs(userOrganizationId, nextToken, sortOrder);
      }
    } catch (error) {
    } finally {
      setResendLoading(false);
      setOpenLogsDrawer(false);
      setLoading(false);
    }
  };
  // "trigger logs" :: end

  // "handle config submit" :: begin
  const handleConfigSubmit = (value, ref) => {
    if (confEditData) {
      handleCreateConfig(value, ref);
      setConfEditData([]);
    } else {
      handleCreateConfig(value, ref, "POST");
      setTimeout(() => {
        handleCreateConfig(value, ref, "PUT");
      }, 300);
    }
    fetchConfigurations();
  };
  // "handle config submit" :: end
  return (
    <>
      <div className="background-white">
        <Row className="App mt-24" justify={"space-between"}>
          <Radio.Group value={activeTab} onChange={handleTabChange}>
            <Radio.Button
              className="custom-button-left-radius"
              value="settings"
            >
              Settings
            </Radio.Button>
            {/* <Radio.Button className="custom-button" value="configurations">
              Configurations
            </Radio.Button> */}
            <Radio.Button className="custom-button-right-radius" value="logs">
              Logs
            </Radio.Button>
          </Radio.Group>

          {hasUpdateAccess && activeTab !== "logs" ? (
            <Button
              shape="round"
              className="dashboard-action-btn"
              onClick={() => setItemModal(true)}
              style={
                activeTab === "configurations" &&
                ncdoConfData?.length > 0 && { display: "none" }
              }
            >
              <EditOutlined />
              {activeTab === "settings"
                ? settingsData?.id
                  ? "Edit"
                  : "Create Settings"
                : "Create Configuration"}
            </Button>
          ) : (
            <Button
              shape="round"
              className="mr-10 dark-primary-btn"
              type="primary"
              onClick={triggerLogs}
              loading={triggerLoading}
            >
              Trigger Now
            </Button>
          )}
        </Row>
        {loading ? (
          <TableSkeleton />
        ) : loadingTable ? (
          <>
            <TableSkeleton />
          </>
        ) : (
          <>
            <SearchableTableWithSorting
              data={
                hasReadAccesss
                  ? activeTab === "logs"
                    ? ncdotLogData
                    : activeTab === "configurations"
                    ? ncdoConfData
                    : ncdotHicamsData
                  : []
              }
              handleChange={handleChange}
              loading={activeTab === "logs" && loading && !scrollLoading}
              loadData={scrollLoading}
              handleScroll={(e) =>
                activeTab === "logs" && handelInfiniteScroll(e)
              }
              columns={
                activeTab === "logs"
                  ? logColumn
                  : activeTab === "configurations"
                  ? configurationColumns
                  : columns
              }
              screens={screens}
              rowKey="id"
            />
          </>
        )}
      </div>
      {activeTab === "settings" ? (
        <NcdotHicamsEdit
          itemModal={itemModal}
          setItemModal={() => setItemModal()}
          loadingData={loading}
          dataClear={() => actionDataClear()}
          handleEditSettings={(value, ref) => handleEditSettings(value, ref)}
          customerlist={customerlist}
          itemTypes={itemTypes}
          customItem={customItem}
          customItemType={customItemType}
          fetchMyAPI={fetchMyAPI}
          fetchCustomData={fetchCustomData}
          loading={loadingData}
          dataToUpdate={settingsData}
        />
      ) : (
        <NcdotHicamsConfigCreate
          configModel={itemModal}
          setConfigModel={() => setItemModal()}
          loading={loading}
          handleCreateConfig={(value, ref) => {
            handleConfigSubmit(value, ref);
          }}
          editData={confEditData}
        />
      )}
      <LogsDrawer
        open={openLogsDrawer}
        screens={screens}
        hasUpdateAccess={hasUpdateAccess}
        hasReadAccess={hasReadAccesss}
        DataClear={DataClear}
        currentOrganization={currentOrganization}
        currentlogs={drawerData}
        resendLogs={() => resendLogs()}
        loadingButton={resendLoading}
      />
    </>
  );
}
