const themeConfig = {
  token: {
    colorPrimary: "#2597FE",
    fontSize: 14,
    borderRadius: 4,
    colorTextBase: "#212121",
    colorPrimaryBg: "#f7f7fd",
  },
};

export default themeConfig;
