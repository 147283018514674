import React, { Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Mainlayout from "../MainLayout/Mainlayout";
import { useSelector } from "react-redux";
import {
  getOrganizationId,
  getCurrentOrganizationIntegrations,
} from "store/slices/loginSlice";
import { SubscribedRoutes } from "utils/Constants";
import { withSubscribedRoute } from "utils/Hoc/withSubscribedRoute";

export default function MainLayoutRoute({ children }) {
  const location = useLocation();
  const token = useSelector(getOrganizationId);
  const subscriptions = useSelector(getCurrentOrganizationIntegrations);
  if (!token) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }
  if (
    SubscribedRoutes.includes(location.pathname) &&
    !withSubscribedRoute(subscriptions, location.pathname)
  ) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      {/* {Rendering the childrens of all Public layout routes using the children(via props) or react-router-dom outlet} */}
      <Mainlayout>
        <Suspense>{children || <Outlet />}</Suspense>
      </Mainlayout>
    </>
  );
}
