import "./typography.less";
import "./responsive.less";
import "./App.less";
// import Routes from "./Router/Routes";
import { Auth, Amplify } from "aws-amplify";
import config from "./aws-exports.js";
import jwt from "jwt-decode";
import { CommonError } from "utils/Helper/CommonError";
import { Switch as AntSwitch } from "antd";
import { useState } from "react";
import { IdenciaImages } from "./Assets/Images";
import {
  isDarkMode,
  setThemeMode,
  updateJWTToken,
} from "store/slices/loginSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Routesapp from "Router/Routes";

const App = () => {
  const { Sun, Moon } = IdenciaImages.Switch;
  const dispatch = useDispatch();
  const isDarkTheme = useSelector(isDarkMode);

  const updatedAwsConfig = {
    ...config,
    API: {
      graphql_headers: async () => {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        const timeStamp = jwt(jwtToken).auth_time;
        const currentUnixTimestamp = Math.floor(Date.now() / 1000);
        const timeDifferenceInSeconds = currentUnixTimestamp - timeStamp;
        const timeDifferenceInDays = Math.floor(
          timeDifferenceInSeconds / (60 * 60 * 24)
        );
        dispatch(updateJWTToken(jwtToken));
        if (
          timeDifferenceInDays >=
          process.env.REACT_APP_COGNITO_REFREST_TOKEN_VALIDITY
        ) {
          Auth.signOut({ global: false })
            .then(() => {
              localStorage.clear();
              window.location.reload();
            })
            .catch((err) => CommonError(err));
        }

        return { Authorization: jwtToken };
      },
    },
  };

  Amplify.configure(updatedAwsConfig);

  const onChange = () => {
    dispatch(setThemeMode("signInUserData"));
  };

  return (
    <div className={isDarkTheme ? "dark-theme App" : "light-theme App"}>
      <Routesapp />
      {/* <AntSwitch
        className="themeSwitch"
        defaultChecked
        onChange={onChange}
        checkedChildren={
          <img src={Sun} style={{ height: "15px", marginTop: "3px" }} alt="" />
        }
        unCheckedChildren={<img src={Moon} style={{ height: "15px" }} alt="" />}
      /> */}
    </div>
  );
};

export default App;
