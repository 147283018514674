import {
  Button,
  Grid,
  Row,
  Input,
  Radio,
  Flex,
  Divider,
  Typography,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import TableSkeleton from "Common/Components/Skeleton/TableSkeleton";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import SearchableTable from "Common/Components/DataTable/DataTable";
// import ItemsGroupEdit from "./ItemsGroupEdit.js/ItemsGroupEdit";
import DeleteModal from "Common/Components/ReusebaleModals/DeleteModal";
import ConfigurationsEdit from "./ForneyVaultEdit/ConfigurationsEdit";
import StrengthTestEdit from "./ForneyVaultEdit/StrengthTestEdit";
import LogsDrawer from "./LogsDrawer";
import { getModuleAccess } from "store/slices/moduleAccessSlice";
import { useSelector } from "react-redux";
import { CommonError } from "utils/Helper/CommonError";
import {
  createForneyVaultConfigurations,
  createForneyVaultSetting,
  forneyVaultConfigurationsByOrganizationID,
  forneyVaultSettingsByOrganizationID,
  searchForneyVaultLogs,
  searchForneyVaultTestResults,
  updateForneyVaultConfigurations,
  updateForneyVaultSetting,
} from "utils/Actions";
import {
  getCurrentIdToken,
  getCurrentOrganization,
  getCurrentUser,
  getOrganizationId,
} from "store/slices/loginSlice";
import SearchableTableWithSorting from "Common/Components/DataTable/DataTableWithSorting";
import {
  dateTimeConversionBasedOnTimeZone,
  debounceFn,
  breakWordsWithSpecialChars,
} from "utils/Helper/commonMethods";
import { fetchForneyResult, forneyvaultStrengthTest, forneyvaultTestLogs, forneyvaultTestResult } from "utils/RESTApi";
import { displayTimeFormat } from "utils/Constants";

export default function ForneyVault() {
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [strengthModal, setStrengthModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [view, setView] = useState("configurations"); // default is 'middle'
  const [currentSetting, setCurrentSetting] = useState(null);
  const [settings, setSettings] = useState([]);
  const [settingToEdit, setSeettingToEdit] = useState();
  const [resultData, setResultData] = useState([]);
  const [currentlogs, setCurrentLogs] = useState();
  const [dataItems, setDataItems] = useState();

  const currentOrganization = useSelector(getCurrentOrganization);

  const [openLogsDrawer, setOpenLogsDrawer] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Search } = Input;
  const currentIdToken = useSelector(getCurrentIdToken);
  const userOrganizationId = useSelector(getOrganizationId);
  const user = useSelector(getCurrentUser);
  const [nextToken, setNextToken] = useState(null);
  const [sortTableOrder, setSortTableOrder] = useState("");
  const [sortTableColumn, setSortTableColumn] = useState("");
  const [loadingScrolls, setLoadingScroll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  //Role ba  const userOrganizationId = useSelector(getOrganizationId);
  const { hasReadAccesss, hasCreateAccess, hasUpdateAccess, hasDeleteAccess } =
    useSelector((state) => getModuleAccess(state, "ForneyVault"));
  useEffect(() => {
    if (view === "configurations") {
      fetchMyAPIItems(userOrganizationId);
    } else if (view === "tests") {
      fetchMyAPII(
        userOrganizationId,
        searchQuery,
        sortTableOrder,
        sortTableColumn,
        null
      );
    } else if (view === "results") {
      fetchResults(
        userOrganizationId,
        searchQuery,
        sortTableOrder,
        sortTableColumn,
        null
      );
    } else if (view === "logs") {
      fetchLogs(
        userOrganizationId,
        searchQuery,
        sortTableOrder || "desc",
        sortTableColumn || "sentOn",
        null
      );
    }
  }, [view, searchQuery]);
  const fetchMyAPII = useCallback(async (id, search, sortOrder, sortColumn, nextTokenData) => {
    setLoading(true);
    try {
      const params = {
        organizationId: id,
        keyword: search,
        limit: 20,
        sortBy: sortColumn,
        sortDir: sortOrder,
        nextToken: nextTokenData,
      };
      const response = await forneyvaultStrengthTest(
        params
      );
      setSettings(response?.items?.items);
    } catch (err) {
      setLoading(false);
      CommonError(err);
    } finally {
      setLoading(false);
    }
  }, []);
  const fetchResults = useCallback(
    async (id, search, sortOrder, sortColumn, nextTokenData) => {
      setLoading(true);
      try {
        const params = {
          organizationId: id,
          keyword: search,
          limit: 20,
          sortBy: sortColumn,
          sortDir: sortOrder,
          nextToken: nextTokenData,
        };
        const response = await forneyvaultTestResult(params);
        await response?.items?.items.sort((a, b) => a?.ageToBreak - b?.ageToBreak);

        if (searchQuery?.length >= 0 && !nextTokenData) {
          setResultData((prevData) => [...response?.items?.items]);
        } else {
          setResultData((prevData) => [...prevData, ...response?.items?.items]);
        }


        setNextToken(response?.items?.nextToken);
      } catch (err) {
        setLoadingScroll(false);
        CommonError(err);
      } finally {
        setLoadingScroll(false);

        setLoading(false);
      }
    },
    [userOrganizationId, searchQuery]
  );
  const fetchLogs = async (
    id,
    search,
    sortOrder,
    sortColumn,
    nextTokenData
  ) => {
    setLoading(true);
    try {
      const params = {
        organizationId: id,
        keyword: search,
        limit: 20,
        sortBy: sortColumn,
        sortDir: sortOrder,
        nextToken: nextTokenData,
      };
      //customproperties by orgid
      const response = await forneyvaultTestLogs(params);
      if (searchQuery?.length >= 0 && !nextTokenData) {
        setLogsData((prevData) => [...response?.items?.items]);
      } else {
        setLogsData((prevData) => [...prevData, ...response?.items?.items]);
      }
      setNextToken(response?.items.nextToken);
    } catch (err) {
      setLoading(false);
      CommonError(err);
    } finally {
      setLoadingScroll(false);
      setLoading(false);
    }
  };

  const fetchMyAPIItems = useCallback(async (id) => {
    setLoading(true);
    try {
      let data = {
        isDeleted: { ne: true },
      };
      const response = await forneyVaultConfigurationsByOrganizationID(
        id,
        data,
        currentIdToken
      );
      setCurrentSetting(response?.items && response?.items[0]);
    } catch (err) {
      setLoading(false);
      CommonError(err);
    } finally {
      setLoading(false);
    }
  }, []);
  const fetchResultsServer = async () => {
    setLoading(true);
    try {
      let params = {
        organizationId: userOrganizationId,
      };

      await fetchForneyResult(params);
      fetchResults(
        userOrganizationId,
        searchQuery,
        sortTableOrder,
        sortTableColumn,
        null
      );
    } catch (err) {
      CommonError(err);
    } finally {
      setLoading(false);
    }
  };
  let strengthColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      className: "w-105px",
      // sorter: (a, b) => {
      //   return a.title.localeCompare(b.title);
      // },
    },
    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "itemType",
      className: "w-165px",
      // sorter: (a, b) => {
      //   return a.itemType.localeCompare(b.itemType);
      // },
      render: (text, value) => {
        return <span>{value?.ItemTypes?.name}</span>;
      },
    },
    {
      title: "Length Custom Property",
      dataIndex: "lengthCustom",
      key: "lengthCustom",
      render: (text, value) => {
        return <span>{value?.LengthProperty?.name}</span>;
      },
    },
    {
      title: "Diameter Custom Property",
      dataIndex: "diameterCustom",
      key: "diameterCustom",
      render: (text, value) => {
        return <span>{value?.DiameterProperty?.name}</span>;
      },
    },
    {
      title: "Design Strength Custom Property",
      dataIndex: "designStrengthCustom",
      key: "designStrengthCustom",
      render: (text, value) => {
        return <span>{value?.ExpectedStrengthProperty?.name}</span>;
      },
    },
    {
      title: "Striping Strength Custom Property",
      dataIndex: "stripingCustom",
      key: "stripingCustom",
      render: (text, value) => {
        return <span>{value?.StrippingStrengthProperty?.name}</span>;
      },
    },
    {
      title: "Pour Date Custom Property",
      dataIndex: "pourDateCustom",
      key: "pourDateCustom",
      render: (text, value) => {
        return <span>{value?.PourDateProperty?.name}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      // className: "display",
      render: (_, value) => {
        return (
          <>
            <Row gutter={[24, 0]}>
              {hasUpdateAccess && (
                <Button
                  type="link"
                  className="p-0 mr-20"
                  onClick={() => {
                    setStrengthModal(true);
                    setSeettingToEdit(value);
                  }}
                >
                  <EditOutlined />
                </Button>
              )}
              {hasDeleteAccess && (
                <Button
                  type="link"
                  className="p-0"
                  danger
                  onClick={() => {
                    setSeettingToEdit(value);
                    setIsDeleteModalVisible(true);
                    setDataItems(value);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              )}
            </Row>
          </>
        );
      },
    },
  ];

  if (!hasUpdateAccess && !hasDeleteAccess)
    strengthColumns = strengthColumns.filter(
      (column) => column.key !== "Action"
    );

  const testResultsColumns = [
    {
      title: "Day",
      dataIndex: "ageToBreak",
      key: "ageToBreak",
      // className: "w-105px",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "AgeToBreak"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        return a?.AgeToBreak - b?.AgeToBreak;
      },
    },
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      // className: "w-165px",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "number"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        return a?.number - b?.number;
      },
    },
    {
      title: "Item Serial Number",
      dataIndex: "itemsId",
      key: "serialNumber",
      render: (text, serialNumber) => {
        return (
          <span className="cursor-pointer">
            {serialNumber?.Items?.serialNumber}
          </span>
        );
      },
    },
    {
      title: "Max Stress",
      dataIndex: "maxStress",
      key: "maxStress",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "maxStress"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.maxStress?.localeCompare(b?.maxStress),
    },
    {
      title: "Max Load",
      dataIndex: "maxLoad",
      key: "maxLoad",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "maxLoad"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.maxLoad?.localeCompare(b?.maxLoad),
    },
    {
      title: "Diameter",
      dataIndex: "diameter",
      key: "diameter",
      sortOrder:
        sortTableColumn === "diameter"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.diameter?.localeCompare(b?.diameter),
    },
    {
      title: "Length",
      dataIndex: "length",
      key: "length",
      sortOrder:
        sortTableColumn === "length"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.length?.localeCompare(b?.length),
    },
    {
      title: "Technician Initials",
      dataIndex: "technicianInitials",
      key: "technicianInitials",
      sortOrder:
        sortTableColumn === "technicianInitials"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) =>
        a?.technicianInitials?.localeCompare(b?.technicianInitials),
    },
    {
      title: "Technician Name",
      dataIndex: "technicianName",
      key: "technicianName",
      sortOrder:
        sortTableColumn === "technicianName"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.technicianName?.localeCompare(b?.technicianName),
    },
    {
      title: "Actual Break Date",
      dataIndex: "actualBreakDayAndTime",
      key: "actualBreakDayAndTime",
      render: (text) => {
        return (
          <span>
            {text && dateTimeConversionBasedOnTimeZone(
              text,
              displayTimeFormat,
              currentOrganization.appTimeZone
            )}
          </span>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "actualBreakDayAndTime"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        const dateA = new Date(a?.actualBreakDayAndTime);
        const dateB = new Date(b?.actualBreakDayAndTime);

        // Compare the dates for sorting
        return dateA - dateB;
      },
    },
    {
      title: "Age at Break",
      dataIndex: "ageAtBreak",
      key: "ageAtBreak",
      render: (text) => {
        return (
          <span>
            {text && dateTimeConversionBasedOnTimeZone(
              text,
              displayTimeFormat,
              currentOrganization.appTimeZone
            )}
          </span>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "ageAtBreak"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        const dateA = new Date(a?.ageAtBreak);
        const dateB = new Date(b?.ageAtBreak);

        // Compare the dates for sorting
        return dateA - dateB;
      },
    },
    {
      title: "Fracture Types",
      dataIndex: "fractureType",
      key: "fractureType",
      sortOrder:
        sortTableColumn === "fractureType"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => a?.fractureType - b?.fractureType,
    },
  ];

  const logsColumns = [
    {
      title: "Date",
      dataIndex: "sentOn",
      key: "sentOn",
      // className: "w-105px",
      render: (text, record) => (
        <span>
          {text &&
            dateTimeConversionBasedOnTimeZone(
              text,
              displayTimeFormat,
              currentOrganization.appTimeZone
            )}
        </span>
      ),
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "sentOn"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        const dateA = new Date(a?.sentOn);
        const dateB = new Date(b?.sentOn);

        // Compare the dates for sorting
        return dateA - dateB;
      },
    },
    {
      title: "Type",
      dataIndex: "httpMethodType",
      key: "httpMethodType",
      // className: "w-165px",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "httpMethodType"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,
      sorter: (a, b) => {
        return a.httpMethodType - b.httpMethodType;
      },
    },

    {
      title: "Serial Number",
      dataIndex: "sNum",
      key: "sNum",
      // className: "w-165px",
      // sortDirections: ["ascend", "descend", "ascend"],
      // sortOrder:
      //   sortTableColumn === "sNum"
      //     ? sortTableOrder === "asc"
      //       ? "ascend"
      //       : "descend"
      //     : null,
      // sorter: (a, b) => {
      //   return a.httpMethodType - b.httpMethodType;
      // },
      render: (text, value) => {
        return <span>{value?.Items?.serialNumber}</span>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        sortTableColumn === "status"
          ? sortTableOrder === "asc"
            ? "ascend"
            : "descend"
          : null,

      sorter: (a, b) => a?.Items?.serialNumbe - b?.Items?.serialNumbe,
    },

    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      // className: "display",
      render: (_, value) => {
        return (
          <Row gutter={[24, 0]}>
            <Typography.Link
              className="text-primary"
              onClick={() => {
                setOpenLogsDrawer(true);
                setCurrentLogs(value);
              }}
            >
              View
            </Typography.Link>
          </Row>
        );
      },
    },
  ];

  const openModal = () => {
    setStrengthModal(true);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const handleSubmitConfiguration = async (values, formikRef) => {
    // Handle form submission here
    setLoading(true);
    values.organizationID = userOrganizationId;
    values.modifiedBy = user?.sub;
    try {
      (await values?.id)
        ? updateForneyVaultConfigurations(values, currentIdToken)
        : createForneyVaultConfigurations(values, currentIdToken);
      setEditModal();
      fetchMyAPIItems(userOrganizationId);
      message.success("Configuration updated successfully");
      formikRef?.current?.resetForm();
    } catch (error) {
      CommonError(error);
    } finally {
      setLoading(false);
    }
  };

  const strenghtSubmit = async (values, formikRef) => {
    // Handle form submission here
    setLoading(true);
    values.modifiedBy = user?.sub;
    values.organizationID = userOrganizationId;
    try {
      (await values?.id)
        ? await updateForneyVaultSetting(values, currentIdToken)
        : await createForneyVaultSetting(values, currentIdToken);
      message.success(
        values?.id
          ? "Setting updated successfully"
          : "Setting added successfully"
      );
      formikRef?.current?.resetForm();
      dataClear();

      fetchMyAPII(userOrganizationId);
    } catch (error) {
      CommonError(error);
    } finally {
    }
  };
  const dataClear = () => {
    setStrengthModal(false);
    setEditModal(false);
    setCurrentLogs();
    setOpenLogsDrawer(false);
    setSeettingToEdit();
    setCurrentSetting();
    setIsDeleteModalVisible(false);
    setLoading(false);
  };
  const handleDeleteSetting = async () => {
    // Handle form submission hered
    setLoading(true);

    const data = {
      id: settingToEdit?.id,
      isDeleted: true,
    };

    try {
      await updateForneyVaultSetting(data, currentIdToken);
      const newArray = settings?.filter(
        (item) => item.id !== settingToEdit?.id
      );
      setSettings(newArray);
      dataClear();
      message.success("Setting deleted successfully");
    } catch (err) {
      CommonError(err);
      setLoading(false);
    } finally {
    }
  };

  const handelInfiniteScroll = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextToken) {
      setLoadingScroll(true);
      await fetchResults(
        userOrganizationId,
        searchQuery,
        sortTableOrder,
        sortTableColumn,
        nextToken
      );
    }
  };
  const handelInfiniteScrollLogs = async (e) => {
    const { target } = e;
    const isBottom =
      Math.round(target.scrollHeight) - Math.round(target.scrollTop) ===
      Math.round(target.clientHeight);
    if (isBottom && nextToken) {
      setLoadingScroll(true);
      await fetchLogs(
        userOrganizationId,
        searchQuery,
        sortTableOrder || "desc",
        sortTableColumn || "sentOn",
        nextToken
      );
    }
  };
  const handleChange = async (pagination, sortData) => {
    let sortOrder = sortData.order === "ascend" ? "asc" : "desc";
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setResultData([]);
    setLogsData([]);
    setNextToken(null);
    await fetchResults(
      userOrganizationId,
      searchQuery,
      sortOrder,
      sortColumn,
      null
    );
  };
  const handleChangeLogs = async (pagination, sortData) => {
    let sortOrder = sortData.order === "ascend" ? "asc" : "desc";
    let sortColumn = sortData.field;
    setSortTableOrder(sortOrder);
    setSortTableColumn(sortColumn);
    setResultData([]);
    setLogsData([]);
    setNextToken(null);
    await fetchLogs(
      userOrganizationId,
      searchQuery,
      sortOrder || "desc",
      sortColumn || "sentOn",
      null
    );
  };
  const handleInputSearch = (searchText) => {
    setNextToken(null);
    debouncedHandleInput(searchText);
  };
  const handleSearch = (value) => {
    setNextToken(null);
    setSearchQuery(value.target.value);
    // You may also want to debounce the search to avoid unnecessary API calls
    // Implement a debounce function if needed
  };
  const debouncedHandleInput = debounceFn(handleSearch, 1000);
  return (
    <>
      <div className="background-white ">
        <Row justify="space-between page-action-wrapper">
          {(view == "results" || view == "logs") && (
            <div>
              <Search
                className="searchbar"
                placeholder="Search"
                onChange={handleInputSearch}
                style={{
                  width: 300,
                }}
              />
            </div>
          )}
          {view === "configurations" && hasUpdateAccess && (
            <Button
              shape="round"
              className={`mr-10 ${view === "configurations" && "ml-auto"}`}
              onClick={() => setEditModal(true)}
            >
              <EditOutlined />
              Edit
            </Button>
          )}
          {(view === "tests" || view === "results") && hasCreateAccess && (
            <Button
              shape="round"
              type="primary"
              className={`mr-10 ${view === "tests" && "ml-auto"}`}
              onClick={() => view === "tests" && openModal()}
            >
              {view === "tests" ? (
                <Flex gap={10}>
                  <PlusOutlined />
                  <span>New Strength Test</span>
                </Flex>
              ) : (
                <Flex gap={10} onClick={() => fetchResultsServer()}>
                  <DownloadOutlined />
                  <span>Fetch Test Results</span>
                </Flex>
              )}
            </Button>
          )}
        </Row>

        <Row className="App">
          <Radio.Group
            value={view}
            onChange={(e) => {
              setNextToken();
              setSortTableOrder();
              setSearchQuery();
              setSeettingToEdit();
              setSortTableColumn();
              setView(e.target.value);
            }}
          >
            <Radio.Button
              className="custom-button-left-radius"
              value="configurations"
            >
              Configurations
            </Radio.Button>
            <Radio.Button value="tests">Strength Tests</Radio.Button>
            <Radio.Button value="results">Test Results</Radio.Button>
            <Radio.Button className="custom-button-right-radius" value="logs">
              Logs
            </Radio.Button>
          </Radio.Group>
        </Row>
        {view === "configurations" &&
          (loading ? (
            <TableSkeleton />
          ) : (
            <div className="pt-30 pb-20 ml-5">
              <Flex>
                <div className="w-100px fw-700">Url</div>
                <div>{currentSetting?.url}</div>
              </Flex>
              <Divider className="mt-20 mb-20" />
              <Flex>
                <div className="w-100px fw-700">Login</div>
                <div>{currentSetting?.login}</div>
              </Flex>
              <Divider className="mt-20 mb-20" />
              <Flex>
                <div className="w-100px fw-700">Password</div>
                <div>{currentSetting?.password && "****"}</div>
              </Flex>
              <Divider className="mt-20 mb-20" />
            </div>
          ))}
        <ConfigurationsEdit
          handleSubmitConfiguration={(value, formik) =>
            handleSubmitConfiguration(value, formik)
          }
          currentSetting={currentSetting}
          editModal={editModal}
          loading={loading}
          setEditModal={setEditModal}
        />
        {view === "tests" && (
          <>
            {loading ? (
              <TableSkeleton />
            ) : (
              <SearchableTable
                data={hasReadAccesss ? settings : []}
                columns={strengthColumns}
                screens={screens}
                rowSelection={rowSelection}
              />
            )}
          </>
        )}
        {view === "tests" && (
          <StrengthTestEdit
            strengthModal={strengthModal}
            setStrengthModal={setStrengthModal}
            dataClear={() => dataClear()}
            strenghtSubmit={(values, currentIdToken) =>
              strenghtSubmit(values, currentIdToken)
            }
            settingToEdit={settingToEdit}
            loading={loading}
          />
        )}
        {view === "results" && (
          <>
            <SearchableTableWithSorting
              data={hasReadAccesss ? resultData : null}
              columns={testResultsColumns}
              screens={screens}
              loading={loading && !loadingScrolls}
              loadData={loadingScrolls}
              handleScroll={(e) => handelInfiniteScroll(e)}
              handleChange={handleChange}
              rowKey="id"
              rowSelection={rowSelection}
            />
          </>
        )}
        {view === "logs" && (
          <>
            <SearchableTableWithSorting
              data={hasReadAccesss ? logsData : null}
              columns={logsColumns}
              screens={screens}
              loading={loading && !loadingScrolls}
              loadData={loadingScrolls}
              handleScroll={(e) => handelInfiniteScrollLogs(e)}
              handleChange={handleChangeLogs}
              rowKey="id"
              rowSelection={rowSelection}
            />
          </>
        )}
      </div>
      <DeleteModal
        isDelete={isDeleteModalVisible}
        header={"Delete " + dataItems?.title}
        setIsDelete={setIsDeleteModalVisible}
        message="Are you sure you want to delete this setting?"
        handleSubmit={() => handleDeleteSetting()}
        loadingdata={loading}
        dataClear={() => dataClear()}
      />
      <LogsDrawer
        open={openLogsDrawer}
        screens={screens}
        displayTimeFormat={displayTimeFormat}
        currentlogs={currentlogs}
        currentOrganization={currentOrganization}
        DataClear={() => dataClear()}
        hasUpdateAccess={hasUpdateAccess}
        hasReadAccess={hasReadAccesss}
      />
    </>
  );
}
