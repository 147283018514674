import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "./DataTable.less";

const SearchableTable = ({
  data,
  columns,
  screens,
  rowSelection,
  hideSelection,
  width,
  rowKey,
  hideScroll,
  className,
}) => {
  // const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  // const handleSearch = (text) => {
  //   const filtered = data.filter((item) =>
  //     Object.values(item).some((value) =>
  //       String(value).toLowerCase().includes(text.toLowerCase())
  //     )
  //   );
  //   setSearchText(text);
  //   setFilteredData(filtered);
  // };
  useEffect(() => {
    setFilteredData(data);
  }, [data]);
  return (
    <div>
      <Table
        className={
          className ? `${className} dashboard-table` : "dashboard-table"
        }
        dataSource={filteredData}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        scroll={{ x: width ? width : 800 }}
        size={screens.xxl ? "middle" : "small"}
        // rowSelection={
        //   !hideSelection && {
        //     type: "checkbox",
        //     ...rowSelection,
        //   }
        // }
        // pagination={{
        //   size: "default",
        //   defaultPageSize: 10,
        //   showSizeChanger: true,
        //   pageSizeOptions: ["10", "20", "30"],
        //   showQuickJumper: true,
        // }}
        locale={{
          emptyText: "No data",
        }}
      />
    </div>
  );
};

export default SearchableTable;
