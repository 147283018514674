import { message } from "antd";
import { Auth } from "aws-amplify";

export const CommonError = async (err) => {
  if (
    err === "No current user" ||
    err?.message === "No current user" ||
    err?.response?.status === 403
  ) {
    message.error(err?.message);
    await Auth.signOut({ global: false });
    localStorage.clear();
    window.location.reload();
  } else if (
    err &&
    err?.errors &&
    err?.errors[0]?.message === "Request failed with status code 401"
  ) {
    message.error(err?.errors[0]?.message);
    await Auth.signOut({ global: false });
    localStorage.clear();
    window.location.reload();
  } else if (Array.isArray(err?.response?.data?.message)) {
    message.error(err.response.data.message[0]);
  } else if (err?.response?.data?.message) {
    message.error(err.response.data.message);
  } else if (err?.response?.data?.message) {
    message.error(err.response.data.message);
  } else if (err?.errors?.[0]?.message) {
    message.error(err?.errors[0]?.message);
  } else if (err?.message) {
    message.error(err?.message);
  } else {
    message.error("Someting went wrong");
  }
};
