import { IdenciaImages } from "Assets/Images";
import { Col, Row, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { LoginImg } = IdenciaImages.Auth;

  const handleBack = () => {
    navigate("/");
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={12} style={{ textAlign: "center" }}>
          <div className="vertical-middle">
            <img src={LoginImg} alt="" />
          </div>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <Typography.Title level={3}>Page Not Found</Typography.Title>
          <Button type="primary" onClick={handleBack}>
            Go Back
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NotFoundPage;
