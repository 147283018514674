import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button, Modal, Checkbox, Select } from "antd";
import { Formik, Form } from "formik";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { debounceFn } from "utils/Helper/commonMethods";
import { CustomPropertyType } from "utils/Constants";
const { Option } = Select;

export default function NcdotHicamsEdit(props) {
  const {
    setItemModal,
    itemModal,
    handleEditSettings,
    loadingData,
    dataClear,
    customerlist,
    itemTypes,
    customItem,
    customItemType,
    fetchMyAPI,
    fetchCustomData,
    loading,
    dataToUpdate,
  } = props;

  const [producerValue, setProducerValue] = useState(null);
  const formikRef = useRef();

  // Data in redux
  const userOrganizationId = useSelector(getOrganizationId);
  const currentIdToken = useSelector(getCurrentToken);

  const producerOptions = [
    { value: 0, label: "Precast" },
    { value: 1, label: "Pre Stressed" },
    { value: 2, label: "Both" },
  ];

  // DataToUpdate side effect :: begin
  useEffect(() => {
    setProducerValue(dataToUpdate?.producerType);
  }, [dataToUpdate]);
  // DataToUpdate side effect :: end

  // API fetch :: begin
  useEffect(() => {
    fetchMyAPI(userOrganizationId);
  }, [itemModal, producerValue]);
  // API fetch :: end

  // Custom Item Type fetch :: begin
  useEffect(() => {
    fetchCustomData(userOrganizationId, CustomPropertyType.items);
    fetchCustomData(userOrganizationId, CustomPropertyType.itemTypes);
  }, [itemModal]);
  // Custom Item Type fetch :: end

  // handle submit function :: begin
  const handleSubmit = async (values) => {
    handleEditSettings(values, formikRef);
  };
  // handle submit function :: end

  // validation schema :: begin
  const validationSchema = Yup.object().shape({
    customerId: Yup.string().when(["restrictedByCustomer"], {
      is: (restrict) => restrict === true,
      then: () => Yup.string().required("Please select customer"),
      otherwise: () => Yup.string().nullable(),
    }),
    producerType: Yup.number().required("Please select producer"),
    itemTypeId: Yup.string().when(["producerType"], {
      is: (value) => value === 0,
      then: () => Yup.string().required("Please select itemTypeId"),
      otherwise: () => Yup.string().nullable(),
    }),
    preStressedItemTypeId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () => Yup.string().required("Please select Pre Stressed"),
      otherwise: () => Yup.string().nullable(),
    }),
    plantIdPropertyId: Yup.string().required("Please select Plant ID"),
    materialDescProperty: Yup.string().when(["producerType"], {
      is: (value) => value === 0 || value === 2,
      then: () =>
        Yup.string().required("Please select Material description property"),
      otherwise: () => Yup.string().nullable(),
    }),
    materialIdPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 0 || value === 2,
      then: () => Yup.string().required("Please select Material ID property"),
      otherwise: () => Yup.string().nullable(),
    }),
    materialTypeIdPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 0 || value === 2,
      then: () =>
        Yup.string().required("Please select Material Type ID property"),
      otherwise: () => Yup.string().nullable(),
    }),
    qtyPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () => Yup.string().required("Please select Qty Property"),
      otherwise: () => Yup.string().nullable(),
    }),
    contractNumberPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () =>
        Yup.string().required("Please select Contract Number Property"),
      otherwise: () => Yup.string().nullable(),
    }),
    concreteMixPropertyId: Yup.string().required(
      "Please select Concrete Mix Property"
    ),
    pourDatePropertyId: Yup.string().required(
      "Please select Pour Date Property"
    ),
    pourNumberPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 0,
      then: () => Yup.string().required("Please select Pour Number property"),
      otherwise: () => Yup.string().nullable(),
    }),
    airContentPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 0,
      then: () => Yup.string().required("Please select Air Content property"),
      otherwise: () => Yup.string().nullable(),
    }),
    slumpPropertyId: Yup.string().when(["spreadPropertyId", "producerType"], {
      is: (spreadPropertyId, producerType) =>
        spreadPropertyId === null && producerType === 0,
      then: () =>
        Yup.string().required("Please select Slump or Spread Property"),
      otherwise: () => Yup.string().nullable(),
    }),
    spreadPropertyId: Yup.string().nullable(),
    flowJRingPropertyId: Yup.string().required(
      "Please select Flow - J Ring Property"
    ),
    bedNumberPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () => Yup.string().required("Please select Bed Number property"),
      otherwise: () => Yup.string().nullable(),
    }),
    standardStrengthIndPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () =>
        Yup.string().required("Please select Standard Strength property"),
      otherwise: () => Yup.string().nullable(),
    }),
    neopreneCapIndPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () => Yup.string().required("Please select Neoprene Cap property"),
      otherwise: () => Yup.string().nullable(),
    }),
    cylinderDiameterPropertyId: Yup.string().when(["producerType"], {
      is: (value) => value === 1,
      then: () =>
        Yup.string().required("Please select Cylinder diameter property"),
      otherwise: () => Yup.string().nullable(),
    }),
  });
  // validation schema :: end

  // Initial Values :: begin
  const initialValues = {
    isEnabled: dataToUpdate?.isEnabled ? dataToUpdate?.isEnabled : false,
    restrictedByCustomer: dataToUpdate?.restrictedByCustomer
      ? dataToUpdate?.restrictedByCustomer
      : false,
    customerId: dataToUpdate?.customerId ? dataToUpdate?.customerId : null,
    producerType: dataToUpdate?.producerType,
    itemTypeId: dataToUpdate?.itemTypeId ? dataToUpdate?.itemTypeId : null,
    preStressedItemTypeId: dataToUpdate?.preStressedItemTypeId
      ? dataToUpdate?.preStressedItemTypeId
      : null,
    plantIdPropertyId: dataToUpdate?.plantIdPropertyId
      ? dataToUpdate?.plantIdPropertyId
      : null,
    materialDescProperty: dataToUpdate?.materialDescProperty
      ? dataToUpdate?.materialDescProperty
      : null,
    materialIdPropertyId: dataToUpdate?.materialIdPropertyId
      ? dataToUpdate?.materialIdPropertyId
      : null,
    materialTypeIdPropertyId: dataToUpdate?.materialTypeIdPropertyId
      ? dataToUpdate?.materialTypeIdPropertyId
      : null,
    qtyPropertyId: dataToUpdate?.qtyPropertyId
      ? dataToUpdate?.qtyPropertyId
      : null,
    contractNumberPropertyId: dataToUpdate?.contractNumberPropertyId
      ? dataToUpdate?.contractNumberPropertyId
      : null,
    concreteMixPropertyId: dataToUpdate?.concreteMixPropertyId
      ? dataToUpdate?.concreteMixPropertyId
      : null,
    pourDatePropertyId: dataToUpdate?.pourDatePropertyId
      ? dataToUpdate?.pourDatePropertyId
      : null,
    pourNumberPropertyId: dataToUpdate?.pourNumberPropertyId
      ? dataToUpdate?.pourNumberPropertyId
      : null,
    airContentPropertyId: dataToUpdate?.airContentPropertyId
      ? dataToUpdate?.airContentPropertyId
      : null,
    slumpPropertyId: dataToUpdate?.slumpPropertyId
      ? dataToUpdate?.slumpPropertyId
      : null,
    spreadPropertyId: dataToUpdate?.spreadPropertyId
      ? dataToUpdate?.spreadPropertyId
      : null,
    flowJRingPropertyId: dataToUpdate?.flowJRingPropertyId
      ? dataToUpdate?.flowJRingPropertyId
      : null,
    bedNumberPropertyId: dataToUpdate?.bedNumberPropertyId
      ? dataToUpdate?.bedNumberPropertyId
      : null,
    standardStrengthIndPropertyId: dataToUpdate?.standardStrengthIndPropertyId
      ? dataToUpdate?.standardStrengthIndPropertyId
      : null,
    neopreneCapIndPropertyId: dataToUpdate?.neopreneCapIndPropertyId
      ? dataToUpdate?.neopreneCapIndPropertyId
      : null,
    cylinderDiameterPropertyId: dataToUpdate?.cylinderDiameterPropertyId
      ? dataToUpdate?.cylinderDiameterPropertyId
      : null,
  };
  // Initial Values :: end

  // handle input search :: begin
  const handleInputSearch = (searchText) => {
    debouncedHandleInput(searchText);
  };

  const handleSearch = (value) => {
    fetchMyAPI(userOrganizationId, value);
  };

  const debouncedHandleInput = debounceFn(handleSearch, 500);
  // handle input search :: end

  // Prestressed Item Options :: begin
  const preStressedItemTypes = itemTypes
    ?.filter((items) => items?.productSegment === 1)
    ?.map((items) => ({
      value: items.id,
      label: items.name,
      type: items?.type,
    }));
  // Prestressed Item Options :: end

  // Precast Item Option :: begin
  const precastItemTypes = itemTypes
    ?.filter((item) => item?.productSegment === 0)
    ?.map((items) => ({
      value: items.id,
      label: items.name,
      type: items?.type,
    }));
  // Precast Item Option :: end

  // handle custom item types search :: begin
  const handleCustomInputSearch = (searchText) => {
    debouncedCustomHandleInput(searchText);
  };

  const handleCustomSearch = (value) => {
    fetchCustomData(userOrganizationId, CustomPropertyType.itemTypes, value);
  };

  const debouncedCustomHandleInput = debounceFn(handleCustomSearch, 500);

  const customItemTypesOption = customItemType
    ?.filter((item) => item?.type === 0)
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      type: item?.propertyOf,
    }));

  const customItemTypesIdOption = customItemType
    ?.filter((item) => item?.type === 2)
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      type: item?.propertyOf,
    }));
  // handle custom item types search :: end

  // handle custom items search :: begins
  const handleCustomItemsInputSearch = (searchText) => {
    debouncedCustomItemsHandleInput(searchText);
  };

  const handleCustomItemsSearch = (value) => {
    fetchCustomData(userOrganizationId, CustomPropertyType.items, value);
  };

  const debouncedCustomItemsHandleInput = debounceFn(
    handleCustomItemsSearch,
    500
  );

  const customItemOption = customItem
    ?.filter((item) => item?.type === 0)
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      type: item?.propertyOf,
    }));

  const customItemIdOption = customItem
    ?.filter((item) => item?.type === 2)
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      type: item?.propertyOf,
    }));

  const customItemDateOption = customItem
    ?.filter((item) => item?.type === 1)
    ?.map((item) => ({
      value: item?.id,
      label: item?.name,
      type: item?.propertyOf,
    }));

  return (
    <div>
      <Modal
        classNames={"py-10"}
        title="Edit Settings"
        width={350}
        centered
        className="modal"
        open={itemModal}
        onCancel={() => {
          setItemModal(false);
          formikRef?.current?.resetForm();
          dataClear && dataClear();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              formikRef?.current?.resetForm();
              setProducerValue(dataToUpdate?.producerType);
            }}
          >
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="editSetting"
            htmlType="submit"
            loading={loadingData}
          >
            Save
          </Button>,
        ]}
      >
        <Formik
          onSubmit={handleSubmit}
          innerRef={formikRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ touched, handleChange, values, errors, setFieldValue }) => {
            return (
              <Form id="editSetting">
                <div className="mb-10 textSpan">
                  <div>
                    <Checkbox
                      name="isEnabled"
                      onChange={(e) => {
                        setFieldValue("isEnabled", e.target.checked);
                      }}
                      checked={values?.isEnabled}
                      value={values?.isEnabled}
                    >
                      Enable
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      name="restrictedByCustomer"
                      onChange={(e) => {
                        setFieldValue("restrictedByCustomer", e.target.checked);
                        if (e.target.checked === false) {
                          setFieldValue("customerId", null);
                        }
                      }}
                      checked={values?.restrictedByCustomer}
                      value={values?.restrictedByCustomer}
                    >
                      <p>Restrict By Customer</p>
                    </Checkbox>
                  </div>
                </div>

                {values?.restrictedByCustomer && (
                  <div className="mb-10">
                    <label htmlFor="customerId">Restrict To Customer</label>
                    <Select
                      value={values?.customerId}
                      name="customerId"
                      placeholder="Choose User"
                      onChange={(e) => {
                        setFieldValue("customerId", e);
                      }}
                    >
                      {customerlist?.map((item) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                    {touched?.customerId && (
                      <ErrorMessageComponent error={errors?.customerId} />
                    )}
                  </div>
                )}

                <div className="mb-10">
                  <label htmlFor="producerType">Product Segment</label>
                  <Select
                    name="producerType"
                    value={values?.producerType}
                    placeholder="Choose Producer"
                    onChange={(e) => {
                      if (e !== null) {
                        setProducerValue(e);
                        setFieldValue("producerType", e);
                      }
                    }}
                  >
                    {producerOptions?.map((item) => (
                      <Option value={item?.value}>{item?.label}</Option>
                    ))}
                  </Select>
                  {touched?.producerType && (
                    <ErrorMessageComponent error={errors?.producerType} />
                  )}
                </div>

                {producerValue !== null && (
                  <>
                    {/* dropdown field :: begins */}
                    {(producerValue === 0 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="itemTypeId">Precast</label>
                        <Select
                          name="itemTypeId"
                          value={values?.itemTypeId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("itemTypeId", e);
                          }}
                          onSearch={async (input) => {
                            handleInputSearch(input);
                          }}
                          placeholder="Select item type"
                        >
                          {precastItemTypes?.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        {touched?.itemTypeId && (
                          <ErrorMessageComponent error={errors?.itemTypeId} />
                        )}
                      </div>
                    )}
                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="preStressedItemTypeId">
                          Pre Stressed
                        </label>
                        <Select
                          name="preStressedItemTypeId"
                          value={values?.preStressedItemTypeId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("preStressedItemTypeId", e);
                          }}
                          onSearch={async (input) => {
                            handleInputSearch(input);
                          }}
                          placeholder="Choose Pre Stressed"
                        >
                          {preStressedItemTypes?.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        {touched?.preStressedItemTypeId && (
                          <ErrorMessageComponent
                            error={errors?.preStressedItemTypeId}
                          />
                        )}
                      </div>
                    )}
                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    <div className="mb-10">
                      <label htmlFor="plantIdPropertyId">
                        Plant ID Property
                      </label>
                      <Select
                        name="plantIdPropertyId"
                        value={values?.plantIdPropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("plantIdPropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomInputSearch(input);
                        }}
                        placeholder="Choose Plant ID"
                      >
                        {customItemTypesOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.plantIdPropertyId && (
                        <ErrorMessageComponent
                          error={errors?.plantIdPropertyId}
                        />
                      )}
                    </div>
                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    <div className="mb-10">
                      <label htmlFor="materialDescProperty">
                        Material Description Property
                      </label>
                      <Select
                        name="materialDescProperty"
                        value={values?.materialDescProperty || null}
                        showSearch
                        filterOption={false}
                        loading={loading}
                        onChange={async (e) => {
                          setFieldValue("materialDescProperty", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomInputSearch(input);
                        }}
                        placeholder="Choose Material Description"
                      >
                        {customItemTypesOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.materialDescProperty && (
                        <ErrorMessageComponent
                          error={errors?.materialDescProperty}
                        />
                      )}
                    </div>
                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    <div className="mb-10">
                      <label htmlFor="materialIdPropertyId">
                        Material ID Property
                      </label>
                      <Select
                        name="materialIdPropertyId"
                        value={values?.materialIdPropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("materialIdPropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomInputSearch(input);
                        }}
                        placeholder="Choose Material ID"
                      >
                        {customItemTypesIdOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.materialIdPropertyId && (
                        <ErrorMessageComponent
                          error={errors?.materialIdPropertyId}
                        />
                      )}
                    </div>
                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    <div className="mb-10">
                      <label htmlFor="materialTypeIdPropertyId">
                        Material Type ID Property
                      </label>
                      <Select
                        name="materialTypeIdPropertyId"
                        value={values?.materialTypeIdPropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("materialTypeIdPropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomInputSearch(input);
                        }}
                        placeholder="Choose Material Type ID"
                      >
                        {customItemTypesIdOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.materialTypeIdPropertyId && (
                        <ErrorMessageComponent
                          error={errors?.materialTypeIdPropertyId}
                        />
                      )}
                    </div>
                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="qtyPropertyId">Qty Property</label>
                        <Select
                          name="qtyPropertyId"
                          value={values?.qtyPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("qtyPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomInputSearch(input);
                          }}
                          placeholder="Choose Quantity"
                        >
                          {customItemTypesIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.qtyPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.qtyPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="contractNumberPropertyId">
                          Contract Number Property
                        </label>
                        <Select
                          name="contractNumberPropertyId"
                          value={values?.contractNumberPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("contractNumberPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomInputSearch(input);
                          }}
                          placeholder="Choose Contract Number"
                        >
                          {customItemTypesOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.contractNumberPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.contractNumberPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {/* dropdown field :: end */}

                    {/* dropdown field :: begins */}
                    <div className="mb-10">
                      <label htmlFor="concreteMixPropertyId">
                        Concrete Mix Property
                      </label>
                      <Select
                        name="concreteMixPropertyId"
                        value={values?.concreteMixPropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("concreteMixPropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomItemsInputSearch(input);
                        }}
                        placeholder="Choose Contract Mix"
                      >
                        {customItemOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.concreteMixPropertyId && (
                        <ErrorMessageComponent
                          error={errors?.concreteMixPropertyId}
                        />
                      )}
                    </div>
                    {/* dropdown field :: end */}

                    <div className="mb-10">
                      <label htmlFor="pourDatePropertyId">
                        Pour Date Property
                      </label>
                      <Select
                        name="pourDatePropertyId"
                        value={values?.pourDatePropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("pourDatePropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomItemsInputSearch(input);
                        }}
                        placeholder="Choose Pour Date"
                      >
                        {customItemDateOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.pourDatePropertyId && (
                        <ErrorMessageComponent
                          error={errors?.pourDatePropertyId}
                        />
                      )}
                    </div>

                    {(producerValue === 0 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="pourNumberPropertyId">
                          Pour Number Property
                        </label>
                        <Select
                          name="pourNumberPropertyId"
                          value={values?.pourNumberPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("pourNumberPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Pour Number"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.pourNumberPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.pourNumberPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 0 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="airContentPropertyId">
                          Air Content Property
                        </label>
                        <Select
                          name="airContentPropertyId"
                          value={values?.airContentPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("airContentPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Air Content"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.airContentPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.airContentPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 0 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="slumpPropertyId">Slump Property</label>
                        <Select
                          name="slumpPropertyId"
                          value={values?.slumpPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("slumpPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Slump"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.slumpPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.slumpPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 0 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="spreadPropertyId">
                          Spread Property
                        </label>
                        <Select
                          name="spreadPropertyId"
                          value={values?.spreadPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("spreadPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Spread"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.spreadPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.spreadPropertyId}
                          />
                        )}
                      </div>
                    )}

                    <div className="mb-10">
                      <label htmlFor="flowJRingPropertyId">
                        Flow - J Ring Property
                      </label>
                      <Select
                        name="flowJRingPropertyId"
                        value={values?.flowJRingPropertyId || null}
                        showSearch
                        filterOption={false} // Disable client-side filtering
                        loading={loading} // Pass loading state to the Select component
                        onChange={async (e) => {
                          setFieldValue("flowJRingPropertyId", e);
                        }}
                        onSearch={async (input) => {
                          handleCustomItemsInputSearch(input);
                        }}
                        placeholder="Choose Flow - J RIng"
                      >
                        {customItemIdOption?.map((item) => (
                          <Option value={item?.value}>{item?.label}</Option>
                        ))}
                      </Select>
                      {touched?.flowJRingPropertyId && (
                        <ErrorMessageComponent
                          error={errors?.flowJRingPropertyId}
                        />
                      )}
                    </div>

                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="bedNumberPropertyId">
                          Bed Number Property
                        </label>
                        <Select
                          name="bedNumberPropertyId"
                          value={values?.bedNumberPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("bedNumberPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Bed Number"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.bedNumberPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.bedNumberPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="standardStrengthIndPropertyId">
                          Standard Strength Ind Property
                        </label>
                        <Select
                          name="standardStrengthIndPropertyId"
                          value={values?.standardStrengthIndPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("standardStrengthIndPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Standard Strength Ind"
                        >
                          {customItemOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.standardStrengthIndPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.standardStrengthIndPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="neopreneCapIndPropertyId">
                          Neoprene Cap Ind Property
                        </label>
                        <Select
                          name="neopreneCapIndPropertyId"
                          value={values?.neopreneCapIndPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("neopreneCapIndPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Neoprene Cap Ind"
                        >
                          {customItemOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.neopreneCapIndPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.neopreneCapIndPropertyId}
                          />
                        )}
                      </div>
                    )}

                    {(producerValue === 1 || producerValue === 2) && (
                      <div className="mb-10">
                        <label htmlFor="cylinderDiameterPropertyId">
                          Cylinder Diameter Property
                        </label>
                        <Select
                          name="cylinderDiameterPropertyId"
                          value={values?.cylinderDiameterPropertyId || null}
                          showSearch
                          filterOption={false} // Disable client-side filtering
                          loading={loading} // Pass loading state to the Select component
                          onChange={async (e) => {
                            setFieldValue("cylinderDiameterPropertyId", e);
                          }}
                          onSearch={async (input) => {
                            handleCustomItemsInputSearch(input);
                          }}
                          placeholder="Choose Cylinder Diameter"
                        >
                          {customItemIdOption?.map((item) => (
                            <Option value={item?.value}>{item?.label}</Option>
                          ))}
                        </Select>
                        {touched?.cylinderDiameterPropertyId && (
                          <ErrorMessageComponent
                            error={errors?.cylinderDiameterPropertyId}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
