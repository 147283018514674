export function withSubscribedRoute(subscriptions, pathname) {
  if (subscriptions) {
    const subscribedData = subscriptions?.filter(
      (integration) =>
        integration?.Modules?.key.toLowerCase() ===
          pathname.replace("/", "").replace("-", "").toLowerCase() &&
        integration.isSubscribed
    );
    if (subscribedData?.length !== 0) {
      return true;
    }
  }
  return false;
}
