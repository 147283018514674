import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import FullPageLayout from "../MainLayout/FullPageLayout";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getOrganizationId } from "store/slices/loginSlice";

const PublicLayoutRoute = ({ children }) => {
  const location = useLocation();
  const token = useSelector(getOrganizationId);

  if (token) {
    return <Navigate replace state={{ from: location }} to="/" />;
  }

  return (
    <FullPageLayout>
      <Suspense fallback={<div>Loading...</div>}>
        {children || <Outlet />}
      </Suspense>
    </FullPageLayout>
  );
};

export default PublicLayoutRoute;
