import { Skeleton } from 'antd';
import React from 'react';

function TableSkeleton() {
  return (
    <div className="mt-20">
      <Skeleton
        active
        paragraph={{
          rows: 5,
        }}
      />
    </div>
  );
}

export default TableSkeleton;

