import { combineReducers } from "redux";
import loginReducer from "./loginSlice";
import stageReducer from "./StageSlice";
import moduleAccessReducer from "./moduleAccessSlice";

const appReducer = combineReducers({
  loginReducer: loginReducer,
  stageReducer: stageReducer,
  moduleAccessReducer: moduleAccessReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    localStorage.clear();
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
