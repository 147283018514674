import { createSlice } from "@reduxjs/toolkit";
import { UserRole } from "utils/Constants";

const initialState = {
  organizationData: null,
  token: null,
  idToken: null,
  userData: null,
  viewingas: null,
  currnetUserData: null,
  orgnizationData: null,
  formLayout: [],
  isDarkTheme: false,
  organizationIntegrations: null,
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    signIn: (state, { payload }) => {
      state.token = payload?.token;
      state.organizationData = payload?.organizationData;
      state.userData = payload?.userData;
      state.idToken = payload?.idToken;
    },
    updateJWTToken: (state, { payload }) => {
      state.token = payload;
    },
    viewAs: (state, { payload }) => {
      state.viewingas = payload;
    },
    currentUser: (state, { payload }) => {
      state.currnetUserData = payload;
    },
    setOrgarnizationData: (state, { payload }) => {
      state.organizationData = payload;
    },
    setOrganizationIntegrations: (state, { payload }) => {
      state.organizationIntegrations = payload;
    },
    setThemeMode: (state, { payload }) => {
      state.isDarkTheme = !state.isDarkTheme;
    },
    setFormLayout: (state, { payload }) => {
      state.formLayout = payload;
    },
  },
});

export const getCurrentToken = (state) => state?.loginReducer?.token;
export const getCurrentIdToken = (state) => state?.loginReducer?.idToken;
export const getCurrentUser = (state) => state?.loginReducer?.userData;
export const getCurrentOrganization = (state) =>
  state?.loginReducer?.organizationData;
export const getCurrentOrganizationIntegrations = (state) =>
  state?.loginReducer?.organizationIntegrations;
export const getCurrentGroups = (state) => state?.loginReducer?.groups;
export const getCurrentUserRole = (state) => state?.loginReducer?.groups;

export const getOrganizationId = (state) =>
  state?.loginReducer?.organizationData?.id;

export const isSuperAdmin = (state) =>
  state?.loginReducer?.userData?.["custom:role"]?.includes(
    UserRole?.SUPERADMIN
  );
export const isLeader = (state) =>
  state?.loginReducer?.groups?.includes("LEADER");
export const isAdmin = (state) =>
  state?.loginReducer?.userData?.["custom:role"] === UserRole?.ADMIN;
export const isManager = (state) =>
  state?.loginReducer?.userData?.["custom:role"]?.includes(UserRole?.MANAGER);
export const isWorker = (state) =>
  state?.loginReducer?.userData?.["custom:role"]?.includes(UserRole?.WORKER);
export const getCurrnetUserData = (state) => state.loginReducer.userData;

export const isDarkMode = (state) => state?.loginReducer?.isDarkTheme;

const { actions, reducer } = loginSlice;
export const {
  signIn,
  viewAs,
  currentUser,
  setOrgarnizationData,
  setFormLayout,
  setThemeMode,
  updateJWTToken,
  setOrganizationIntegrations,
} = actions;
export default reducer;
