import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roleBasedAccess: null,
};

const moduleAccessSlice = createSlice({
  name: "moduleAccessSlice",
  initialState,
  reducers: {
    setRoleBasedModuleAccesses: (state, { payload }) => {
      state.roleBasedAccess = payload;
    },
  },
});

const getAccessByType = (accesses) => {
  return {
    hasReadAccesss: accesses?.[0]?.deniedFields
      ? !accesses?.[0]?.deniedFields?.includes("read")
      : true,
    hasCreateAccess: accesses?.[0]?.deniedFields
      ? !accesses?.[0]?.deniedFields?.includes("create")
      : true,
    hasUpdateAccess: accesses?.[0]?.deniedFields
      ? !accesses?.[0]?.deniedFields?.includes("update")
      : true,
    hasDeleteAccess: accesses?.[0]?.deniedFields
      ? !accesses?.[0]?.deniedFields?.includes("delete")
      : true,
  };
};

export const getModuleAccess = (state, key) =>
  getAccessByType(
    state?.moduleAccessReducer?.roleBasedAccess?.filter(
      (module) => module?.Modules?.key === key
    )
  );

const { actions, reducer } = moduleAccessSlice;

export const { setRoleBasedModuleAccesses } = actions;

export default reducer;
