import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFormElementID: null,
  formElementUpdate: null,
  stageName: null,
  currentStageDetails: null,
  stag1Details: null,
};

const stageSlice = createSlice({
  name: 'stageSlice',
  initialState,
  reducers: {
    setCurrentFormElementID: (state, { payload }) => {
      state.currentFormElementID = payload;
    },
    setFormElementUpdate: (state, { payload }) => {
      state.formElementUpdate = payload;
    },
    setStageName: (state, { payload }) => {
      state.stageName = payload;
    },
    setcurrentStageDetails: (state, { payload }) => {
      state.currentStageDetails = payload;
    },
    setStage1Detail: (state, { payload }) => {
      state.stag1Details = payload;
    },
  },
});

export const getCurrentFormElementID = (state) =>
  state?.stageReducer?.currentFormElementID;

export const getformElementUpdate = (state) =>
  state?.stageReducer?.formElementUpdate;

export const getStageName = (state) => state?.stageReducer?.stageName;

export const getCurrentStageDetail = (state) =>
  state?.stageReducer?.currentStageDetails;

export const getStag1Details = (state) => state?.stageReducer?.stag1Details;

const { actions, reducer } = stageSlice;
export const {
  setCurrentFormElementID,
  setFormElementUpdate,
  setStageName,
  setcurrentStageDetails,
  setStage1Detail,
} = actions;
export default reducer;

