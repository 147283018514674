import React, { useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Modal, Button } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";

const ConfigurationsEdit = (props) => {
  const formikRef = useRef();
  // const [loading, setLoading] = useState(false);
  const {
    setEditModal,
    editModal,
    handleSubmitConfiguration,
    currentSetting,
    loading,
  } = props;

  const validationSchema = Yup.object().shape({
    login: Yup.string().required("Login is required"),
    url: Yup.string().required("Url is required"),
    password: Yup.string().required("Password is required"),
  });
  const initialValues = {
    password: currentSetting?.id ? currentSetting?.password : "",
    url: currentSetting?.id ? currentSetting?.url : "",
    login: currentSetting?.id ? currentSetting?.login : "",
  };

  const handleSubmit = async (values) => {
    if (currentSetting?.id) values.id = currentSetting?.id;
    handleSubmitConfiguration(values, formikRef);
  };

  return (
    <Modal
      title="Edit Configurations"
      width={350}
      centered
      className="modal"
      open={editModal}
      maskClosable={true}
      onCancel={() => {
        formikRef?.current?.resetForm();
        setEditModal();
      }}
      footer={[
        <Button key="cancel" onClick={() => formikRef?.current?.resetForm()}>
          Reset
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="configurationsForm"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, handleChange }) => (
          <Form id="configurationsForm">
            <div className="mb-10">
              <label htmlFor="name">Url</label>

              <Field
                type="text"
                name="url"
                as={Input}
                placeholder="Enter Url"
                autoComplete="off"
              />
              {touched?.url && <ErrorMessageComponent error={errors?.url} />}
            </div>
            <div className="mb-10">
              <label htmlFor="name">Login</label>

              <Field
                type="text"
                name="login"
                as={Input}
                placeholder="Enter Login"
                autoComplete="off"
              />
              {touched?.login && (
                <ErrorMessageComponent error={errors?.login} />
              )}
            </div>
            <div className="mb-10">
              <label htmlFor="name">Password</label>

              <Field
                type="password"
                name="password"
                as={Input}
                placeholder="Enter Password"
                autoComplete="off"
              />
              {touched?.password && (
                <ErrorMessageComponent error={errors?.password} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ConfigurationsEdit;
