export const IdenciaImages = {
  Switch: {
    Sun: require("../Assets/sun.png"),
    Moon: require("../Assets/moon.png"),
  },
  Auth: {
    Logo: require("../Assets/logo.svg").default,
    LogoResponsive: require("../Assets/sidebarIcons/logo-responsive.svg")
      .default,
    LogoWhite: require("../Assets/white-logo.svg").default,
    IdenciaLogo: require("../Assets/IdenciaLogo.svg").default,
    AuthLogo: require("../Assets/Auth-Logo.svg").default,
    LoginImg: require("../Assets/authPages/login.svg").default,
    SuperAdmin: require("../Assets/authPages/super-admin.svg").default,
    SelectOrg: require("../Assets/authPages/select-org.svg").default,
    signupSlide1: require("../Assets/authPages/signupSlide1.svg").default,
    signupSlide2: require("../Assets/authPages/signupSlide2.svg").default,
    signupSlide3: require("../Assets/authPages/signupSlide3.svg").default,
    Background: require("../Assets/authPages/lightBlueBG.svg").default,
    CreateOrg: require("../Assets/authPages/create-org.svg").default,
    CreateUser: require("../Assets/authPages/create-user.svg").default,
    Access: require("../Assets/authPages/access.svg").default,
    SubscriptionManagement:
      require("../Assets/authPages/subscription-management.svg").default,
  },
  Items: {
    Attachment: require("../Assets/Items/Attchments.svg").default,
    AddItems: require("../Assets/Items/add.svg").default,
    removeItems: require("../Assets/Items/remove.svg").default,
  },
  Projects: {
    ProjectSidebarIcon: require("../Assets/Projects/ProjectSidebarIcon.svg")
      .default,
  },

  General: {
    CloserIcon: require("../Assets/General/close.svg").default,
    openrIcon: require("../Assets/General/right.svg").default,
    Map: require("../Assets/General/map.svg").default,
    Sign: require("../Assets/General/sign.svg").default,
    ReportPage: require("../Assets/ReportPage.svg").default,
  },
  sidebarIcons: {
    Items: require("../Assets/sidebarIcons/items.svg").default,
    Dashboard: require("../Assets/sidebarIcons/dashboard.svg").default,
    ItemGroup: require("../Assets/sidebarIcons/itemGroup.svg").default,
    sideMenu: require("../Assets/sidebarIcons/sidelogo.svg").default,
    users: require("../Assets/sidebarIcons/users.svg").default,
    report: require("../Assets/sidebarIcons/reports.svg").default,
    Documents: require("../Assets/sidebarIcons/documents.svg").default,

    MessageIcon: require("../Assets/sidebarIcons/message.svg").default,
  },
};
