import React, { useState } from "react";
import { Drawer, Button, Typography, Row } from "antd";
import { DoubleRightOutlined, EditOutlined } from "@ant-design/icons";
import SearchableTable from "Common/Components/DataTable/DataTable";
import TableSkeleton from "Common/Components/Skeleton/TableSkeleton";
import { dateTimeConversionBasedOnTimeZone } from "utils/Helper/commonMethods";
import { toUpper } from "lodash";
import { IdenciaImages } from "Assets/Images";

export default function LogsDrawer({
  open,
  loading,
  currentlogs,
  screens,
  displayTimeFormat,
  currentOrganization,
  DataClear = () => {},
  resendLogs = () => {},
  loadingButton,
}) {
  const { openrIcon } = IdenciaImages.General;

  const logProperties = [
    { title: "Method", path: "httpMethodType" },
    { title: "End point", path: "apiEndPoint" },
    { title: "created At", path: "createdAt", transform: true },
    { title: "Is Sent", path: "isSent" },
    { title: "Status", path: "status" },
    { title: "Items", path: "Items?.serialNumber" },
    { title: "request", path: "request" },
    { title: "response", path: "response" },
  ];

  const newArray =
    currentlogs &&
    logProperties
      .map(({ title, path, transform = false }) => {
        let value;
        if (transform) {
          value = dateTimeConversionBasedOnTimeZone(
            currentlogs?.[path],
            displayTimeFormat,
            currentOrganization.appTimeZone
          );
        } else {
          value = currentlogs?.[path];
        }

        if (title === "Method") {
          value = toUpper(currentlogs?.[path]);
        }

        if (title === "Is Sent" && value === true) {
          value = <img src={openrIcon} alt="close" />;
        }

        if (title === "Items") {
          value = currentlogs?.Items?.serialNumber;
        }
        return { title, value };
      })
      .filter(Boolean);

  const columnsaa = [
    {
      title: "Custom Property",
      dataIndex: "title",
      key: "title",
      render: (text, value) => {
        return <span className="cursor-pointer">{text}</span>;
      },
      width: "30%",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, value) => {
        return <span className="cursor-pointer">{text}</span>;
      },
    },
  ];

  const [isFullScreen, setFullScreen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(706);

  const setFullScreenWidth = (value) => {
    if (value) {
      window.getScreenDetails().then((res) => {
        let width = 0;
        if (document.activeElement.clientWidth > 1024) {
          width = document.activeElement.clientWidth - 269;
        } else {
          width = document.activeElement.clientWidth;
        }
        setDrawerWidth(width);
        setFullScreen(value);
      });
    } else {
      setFullScreen(value);
      setDrawerWidth(706);
    }
  };

  return (
    <Drawer
      className="related-item-drawer brawer-with-btn"
      width={drawerWidth}
      title={
        <>
          <Row align="middle" justify="space-between" className="drawer-title">
            <Row align="middle">
              <Button
                type="text"
                className="text-gray fs-14 p-0"
                onClick={() => {
                  DataClear();
                }}
              >
                <DoubleRightOutlined />
              </Button>
              <Typography.Title className="mb-0 ml-10 fs-21">
                {currentlogs?.__typename}
              </Typography.Title>
            </Row>
            <Typography.Title
              level={5}
              className="mb-0 ml-10 text-gray fw-400 fs-14 cursor-pointer text-primary"
              onClick={() => {
                setFullScreenWidth(!isFullScreen);
              }}
            >
              {isFullScreen ? "View in small screen" : "  View in fullscreen"}
            </Typography.Title>
          </Row>
        </>
      }
      closeIcon={null}
      closable={false}
      placement={screens.xs ? "bottom" : "right"}
      onClose={() => DataClear()}
      open={open}
    >
      <Row justify={"end"} className="mb-10">
        <Button
          shape="round"
          className="dashboard-action-btn"
          loading={loadingButton}
          onClick={() => resendLogs()}
        >
          Resend
        </Button>
      </Row>
      <div>
        {loading ? (
          <TableSkeleton />
        ) : (
          <SearchableTable
            data={newArray || []}
            width={200}
            columns={columnsaa}
            screens={screens}
            hideSelection
          />
        )}
      </div>
    </Drawer>
  );
}
