import React, { useCallback, useState } from "react";
import { Layout } from "antd";
import "MainLayout/mainlayout.less";
import HeaderLayout from "../Common/Layouts/Header";
import Sidebar from "../Common/Layouts/Sidebar";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCurrentUser,
  getCurrentIdToken,
  getCurrentToken,
} from "store/slices/loginSlice";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { getUser } from "utils/Actions";
import { onUpdateUserCustom } from "graphql/customSubscriptions";
const { Content } = Layout;

export default function MainLayout(props) {
  // State for sidebar drawer
  const [open, setOpen] = useState(false);
  const user = useSelector(getCurrentUser);
  const currentIdToken = useSelector(getCurrentToken);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  // const fetchMyAPI = useCallback(async (userId) => {
  //   try {
  //     const response = await getUser(userId, currentIdToken);
  //     if (response?.isDeleted || !response?.isEnabled) {
  //       Auth.signOut({
  //         global: false,
  //       })
  //         .then(() => {
  //           localStorage.clear();
  //           window.location.reload();
  //         })
  //         .catch((err) => {});
  //     }
  //   } catch (err) {
  //   } finally {
  //   }
  // }, []);
  // useEffect(() => {
  //   if (user?.sub) {
  //     // fetchMyAPI(user?.sub);

  //     const Accountsubscriber = API.graphql(
  //       graphqlOperation(onUpdateUserCustom, {
  //         filter: { id: { eq: user?.sub } },
  //       })
  //     ).subscribe({
  //       next: (d) => {
  //         if (
  //           d?.value?.data?.onUpdateUser?.isDeleted === true ||
  //           d?.value?.data?.onUpdateUser?.isEnabled === false
  //         ) {
  //           Auth.signOut({
  //             global: false,
  //           })
  //             .then(() => {
  //               localStorage.clear();
  //               window.location.reload();
  //             })
  //             .catch((err) => {});
  //         }
  //       },
  //       error: (e) => {},
  //     });

  //     return () => {
  //       Accountsubscriber?.unsubscribe();
  //     };
  //   }
  // }, [user?.sub]);
  return (
    <Layout className="layout">
      <Sidebar onClose={onClose} open={open} setOpen={setOpen} />
      <Layout>
        <HeaderLayout showDrawer={showDrawer} />
        <MainContent>{props.children}</MainContent>
      </Layout>
    </Layout>
  );
}

function MainContent({ children }) {
  return (
    <Content className="main" id="main">
      {children}
    </Content>
  );
}
