import React, { useEffect, useState } from "react";
import { Input, Modal, Button, Radio } from "antd";
import "./../Component.less";
export default function SelectOrganization(props) {
  const {
    setEditOrganizationFlag,
    editOrganizationFlag,
    setItemModal,
    itemModal,
    organizationData: allOrganizationData,
    organizationSelect,
    nextToDashBoard,
    currentOrganization,
    nextEditOrganizationFlag,
    setNextEditOrganizationFlag,
    setDuplicateOrganization,
    duplicateOrganization,
    setNextDuplicateOrganizationFlag,
    nextDuplicateOrganizationFlag,
  } = props;

  useEffect(() => {
    const sortedOrganizations = allOrganizationData?.sort((a, b) => {
      return a.name.localeCompare(b.name); // Sorting by 'name' in ascending order
    });

    setOrganizationData(sortedOrganizations);
  }, [allOrganizationData]);
  const { Search } = Input;

  const [searchTerm, setSearchTerm] = useState("");
  const [organizationData, setOrganizationData] = useState(allOrganizationData);

  const handleSearch = (value) => {
    setSearchTerm(value);
    // Filter organizations based on the search term
    const filteredOrganizations = allOrganizationData.filter((organize) =>
      organize.name.toLowerCase().includes(value.toLowerCase())
    );
    setOrganizationData(filteredOrganizations);
  };
  const handleSubmit = () => {
    if (setEditOrganizationFlag) {
      setEditOrganizationFlag(false);
    }
    if (setDuplicateOrganization) {
      setDuplicateOrganization(false);
    }

    if (editOrganizationFlag) setNextEditOrganizationFlag(true);
    if (duplicateOrganization) setNextDuplicateOrganizationFlag(true);

    setItemModal(false);
    if (!editOrganizationFlag && !duplicateOrganization) {
      return nextToDashBoard();
    }
  };

  return (
    <Modal
      title="Select Organization"
      width={350}
      centered
      maskClosable={true}
      className="modal mb-10 one-btn-footer organizationModal"
      open={itemModal || editOrganizationFlag || duplicateOrganization}
      onCancel={() => {
        if (setEditOrganizationFlag) setEditOrganizationFlag(false);
        if (setDuplicateOrganization) setDuplicateOrganization(false);
        setItemModal(false);
      }}
      footer={null}
    >
      <div className="mb-10">
        <div>
          <Search
            className="searchbar"
            placeholder="Search"
            style={{
              width: 300,
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-10">
        {organizationData?.map((organize) => (
          <div className="mb-10" key={organize?.id}>
            <Radio
              checked={organize?.id === currentOrganization?.id}
              onChange={() => organizationSelect(organize)}
            >
              {organize?.name}
            </Radio>
          </div>
        ))}
      </div>
      <div
        style={{
          position: "sticky",
          bottom: "-1rem",
          left: "-1rem",
          right: "-1rem",
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          textAlign: "right",
          backgroundColor: "#fff",
        }}
      >
        <Button
          key="submit"
          type="primary"
          shape="round"
          htmlType="submit"
          className="w-100"
          onClick={() => handleSubmit()}
          disabled={!currentOrganization}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
}
